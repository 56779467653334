<!--edit -->
<nb-card class="modal-container">
  <nb-card-header>
      <h5 class="modal-title">Edit User</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="editUserForm">
      <div class=" form-row">
        <div class="col-12 mb-3">
          <label  class="col-form-label">User Name</label>
          <input type="text" fullWidth nbInput formControlName="username" class="input-style">
          <small *ngIf="this.editUserForm.get('username')?.touched && this.editUserForm.get('username')!.errors?.['required']" class="text-danger">username is required</small>
        </div>
        <div class="col-12 mb-3">
          <label  class="col-form-label">Email</label>
          <input type="email" fullWidth nbInput formControlName="email" class="input-style" [value]="user.email">
          <small *ngIf="this.editUserForm.get('email')!.touched && this.editUserForm.get('email')!.errors?.['required']" class="text-danger">email is required</small>
          <small *ngIf="this.editUserForm.get('email')!.touched  && this.editUserForm.get('email')!.errors?.['email']" class="text-danger">invalid email address</small>
          
        </div>
        <div class="col-12 mb-3">
          <label  class="col-form-label">Phone Number</label>
          <input type="text" fullWidth nbInput formControlName="phoneNumber" class="input-style" [value]="user.phoneNumber">
          <small *ngIf="this.editUserForm.get('phoneNumber')!.touched  && this.editUserForm.get('phoneNumber')!.errors?.['required']" class="text-danger">phone number is required</small>
          <small *ngIf="this.editUserForm.get('phoneNumber')!.touched  && this.editUserForm.get('phoneNumber')!.errors?.['pattern']" class="text-danger">invalid phone number</small>
        </div>

        <div class="col-12 mb-3" *ngIf="roles.length">
          <label  class="col-form-label">Role</label>
          <nb-select placeholder="Select User Role" fullWidth formControlName="roleId">
            <nb-option *ngFor="let role of roles" [value]="role.id" >
              {{role.name}}</nb-option>
          </nb-select>
          <small *ngIf="this.editUserForm.get('roleId')!.touched  && this.editUserForm.get('roleId')!.errors?.['required']" class="text-danger">role is required</small>

        </div>
        <div class="col-12 mb-3" *ngIf="businessBranches.length">
          <label  class="col-form-label">Accessible Branches</label>
          <nb-select multiple placeholder="Select Accessible Branches For this user" fullWidth formControlName="accessibleBranches">
            <nb-option *ngFor="let branch of businessBranches" [value]="branch.id" >
              {{branch.name}}</nb-option>
          </nb-select>
          <small *ngIf="this.editUserForm.get('accessibleBranches')!.touched && this.editUserForm.get('accessibleBranches')!.errors?.['required']" class="text-danger">accessible Branches is required</small>
        </div>
        
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-end">
    <button nbButton status="danger" (click)="cancel()">
      <nb-icon icon="close-outline"></nb-icon>Cancel
    </button>
    <button nbButton status="success" [disabled]="editUserForm.invalid && submitted" (click)="editUser()">
      <nb-icon icon="save-outline"></nb-icon>Save
    </button>
  </nb-card-footer>
</nb-card>
<!--edit-->