<!--add -->
<nb-card class="model-full">
  <nb-card-header>
      <h5 class="modal-title">Add User</h5>
  </nb-card-header>
  <nb-card-body *ngIf="selectsLoaded">
    <form [formGroup]="addUserForm">
      <div class="row form-row">
      <div class="col-12 mb-3">
        <label class="col-form-label">User Name</label>
        <input type="text" fullWidth nbInput  formControlName="userName" class="input-style" placeholder="Enter User Name">
        <small *ngIf="this.addUserForm.get('userName')?.touched && !this.addUserForm.get('userName')?.valid" class="text-danger">username is required</small>

      </div>
      <div class="col-12  mb-3">
        <label class="col-form-label">Email</label>
        <input type="email" fullWidth nbInput formControlName="email" name="email"  class="input-style" placeholder="Enter User Email">
        <small *ngIf="this.addUserForm.get('email')?.touched && this.addUserForm.get('email')?.errors?.['required']" class="text-danger">email is required</small>
        <small *ngIf="this.addUserForm.get('email')?.touched && this.addUserForm.get('email')?.errors?.['email']" class="text-danger">invalid email</small>
        
      </div>
      <div class="col-12  mb-3">
        <label class="col-form-label">Phone Number</label>
        <input type="text" fullWidth nbInput formControlName="phoneNumber" name="phoneNumber"  class="input-style" placeholder="Enter User Phone Number">
        <small *ngIf="this.addUserForm.get('phoneNumber')?.touched && this.addUserForm.get('phoneNumber')?.errors?.['required']" class="text-danger">phone number is required</small>
        <small *ngIf="this.addUserForm.get('phoneNumber')?.touched && this.addUserForm.get('phoneNumber')?.errors?.['pattern']" class="text-danger">invalid phone number</small>
      </div>
      <div class="col-12 mb-3">
        <label class="col-form-label">Role</label>

        <nb-select placeholder="Select User Role" fullWidth formControlName="roleId">
          <nb-option *ngFor="let role of roles"  [value]="role.id">{{role.name}}</nb-option>
        </nb-select>
        <small *ngIf="this.addUserForm.get('roleId')!.touched && this.addUserForm.get('roleId')?.errors?.['required']" class="text-danger">role is required</small>
        
      </div>
      <div class="col-12 mb-3">
        <label class="col-form-label">Accessible Branches</label>
        
        <nb-select multiple placeholder="Select Accessible Branches for this user" fullWidth formControlName="accessibleBranches">
          <nb-option *ngFor="let branch of businessBranches"  [value]="branch.id">{{branch.name}}</nb-option>
        </nb-select>
        <small *ngIf="this.addUserForm.get('accessibleBranches')!.touched && this.addUserForm.get('accessibleBranches')!.errors?.['required'] " class="text-danger">accessible Branches is required</small>

      </div>
      <div class="col-12 mb-3">
        <label class="col-form-label"> Password </label>
        <nb-form-field>
          <input [type]="getInputType()" class="input-style" nbInput fullWidth placeholder="Enter User Password" formControlName="password">
          <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
            <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                    pack="eva"
                    [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
            </nb-icon>
          </button>
        </nb-form-field>
        <small [class.text-danger]="this.addUserForm.get('password')!.touched && this.addUserForm.get('password')!.invalid">Password must be at least 8 and at max 100 characters long</small>
      </div>
      <div class="col-12 mb-3">
        <label class="col-form-label">Retype - Password </label>
        <nb-form-field>
          <input [type]="getInputType1()" class="input-style" nbInput fullWidth placeholder="Retype User Password" [(ngModel)]="confirmpassword" [ngModelOptions]="{standalone: true}">
          <button nbSuffix nbButton ghost (click)="toggleShowPassword1()">
            <nb-icon [icon]="showconfirmPassword ? 'eye-outline' : 'eye-off-2-outline'"
                    pack="eva"
                    [attr.aria-label]="showconfirmPassword ? 'hide password' : 'show password'">
            </nb-icon>
          </button>
        </nb-form-field>
        <small class="text-danger" *ngIf="addUserForm.get('password')!.value != confirmpassword && addUserForm.get('password')!.valid"> confirmed password doesn't match password</small>
      </div>
      </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-end">
    <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
    <button nbButton  status="success" [disabled]="addUserForm.invalid && submitted" (click)="submit()"><nb-icon icon="save-outline"></nb-icon>Save</button>
    <small class="text-danger d-block" *ngIf="(addUserForm.invalid || addUserForm.get('password')!.value != confirmpassword) && submitted">form can't be submitted, there is one or more validation errors</small>
  </nb-card-footer>
</nb-card>
<!--add-->

