<nb-card class="m-0">
    <nb-card-header>
        <h5>{{area ? 'Edit Area' : 'Add Area'}}</h5>
    </nb-card-header>
    <nb-card-body>
        <form [formGroup]="addAreaForm">
            <div class="form-row">
                <div class="mb-3 col-12">
                    <label class="mb-2">City</label>
                    <input  nbInput placeholder="Enter City" [nbAutocomplete]="autoCity" fullWidth formControlName="cityName" />
                    <nb-autocomplete  #autoCity>
                        <nb-option *ngFor="let city of filteredCities" (click)="selectCity(city)">
                            {{ city.name }}
                        </nb-option>
                        <nb-option *ngIf="filteredCities.length === 0 && !edit">
                            No city found.  you will use  "{{ addAreaForm.get('cityName')?.value }}" as the new city.
                        </nb-option>
                    </nb-autocomplete>
                    <small class="text-danger"
                        *ngIf="submitted && addAreaForm.get('cityName')!.invalid && addAreaForm.get('cityName')!.errors?.['required']">
                        City is required.
                    </small>
                </div>

                <div class="mb-3 col-12">
                    <label class="mb-2">Area</label>
                    <input   nbInput placeholder="Enter Area" [nbAutocomplete]="autoArea" fullWidth formControlName="areaName" />
                    <nb-autocomplete #autoArea>
                        <nb-option *ngFor="let area of filteredAreas" (click)="selectArea(area)">
                            {{ area.name }}
                        </nb-option>
                        <nb-option *ngIf="filteredAreas.length === 0 && !edit">
                            No area found. You will"{{ addAreaForm.get('areaName')?.value }}" as the new area.
                        </nb-option>
                    </nb-autocomplete>
                    <small class="text-danger"
                        *ngIf="submitted && addAreaForm.get('areaName')!.invalid && addAreaForm.get('areaName')!.errors?.['required']">
                        Area is required.
                    </small>
                </div>

                <div class="mb-3 col-12">
                    <label class="mb-2">Minimum Order</label>
                    <input type="number" formControlName="minimumOrderAmount" nbInput fullWidth placeholder="Enter Minimum Order amount">
                    <small class="text-danger"
                        *ngIf="submitted && addAreaForm.get('minimumOrderAmount')!.invalid && addAreaForm.get('minimumOrderAmount')!.errors?.['required']">
                        Minimum Order is required.
                    </small>
                </div>

                <div class="mb-3 col-12">
                    <label class="mb-2">Fee</label>
                    <input type="number" formControlName="deliveryFee" nbInput fullWidth placeholder="Enter Fee amount">
                    <small class="text-danger"
                        *ngIf="submitted && addAreaForm.get('deliveryFee')!.invalid && addAreaForm.get('deliveryFee')!.errors?.['required']">
                        Fee is required.
                    </small>
                </div>

                <div class="mb-3 col-12">
                    <label class="mb-2">Max Discount</label>
                    <input type="number" formControlName="maximumDeliveryDiscount" nbInput fullWidth placeholder="Enter Max Discount amount">
                    <small class="text-danger"
                        *ngIf="submitted && addAreaForm.get('maximumDeliveryDiscount')!.invalid && addAreaForm.get('maximumDeliveryDiscount')!.errors?.['required']">
                        Max Discount is required.
                    </small>
                </div>
            </div>
        </form>
    </nb-card-body>
    <nb-card-footer class="text-right">
        <button nbButton status="basic" (click)="onCancel()">Cancel</button>
        <button nbButton status="success" (click)="onSave()">Save</button>
    </nb-card-footer>
</nb-card>
