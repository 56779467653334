<nb-card>
  <nb-card-header>
    <h5 class="modal-title">Delete Branch Creation Request </h5>
  </nb-card-header>
  <nb-card-body>
    <div class="p-2">
      Are you sure You Want To Delete This Request ?
    </div>
  </nb-card-body>
  <nb-card-footer>
      <div class="d-flex justify-content-end">
        <button nbButton status="basic" (click)="onCancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
        <button nbButton status="warning" (click)="onConfirm()"><nb-icon icon="trash-2-outline"></nb-icon>Delete</button>
      </div>
    </nb-card-footer>
</nb-card>