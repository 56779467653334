<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-center justify-content-end">
      <button nbButton hero status="success" (click)="onCreateArea()">
        <nb-icon icon="plus-outline"></nb-icon> Create Area
      </button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- <div class="table-responsive" *ngIf="tables.length > 0"> -->
    <div class="table-responsive">
      <table class="table table-bordered" [nbSpinner]="loading" nbSpinnerStatus="basic">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">City</th>
            <th scope="col">Area</th>
            <th scope="col">Discount</th>
            <th scope="col">Min Order</th>
            <th scope="col">fees</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let area of areas; let i = index ">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{area.area.city.name}}</td>
            <td>{{area.area.name}}</td>
            <td>{{area.maxDeliveryDiscount}}</td>
            <td>{{area.minOrderAmount + ' '+ area.area.city.country.currency}}</td>
            <td>{{area.deliveryFee + ' '+ area.area.city.country.currency}}</td>
            <td>
              <button placement="top" ngbTooltip="Edit" class="mx-md-2 my-2 my-md-0" nbButton status="warning"
                size="small" shape="round" hero (click)="onEdit(area)">
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
              <button class="m-0" placement="right" ngbTooltip="Delete Area" nbButton status="danger" size="small"
                shape="round" hero (click)="onDelete(area.id)">
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="py-3 text-center" *ngIf="areas.length == 0">
      <p>No available tables to show</p>
    </div>
  </nb-card-body>
</nb-card>