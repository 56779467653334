import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';

import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { SmsNotification } from '../models/smsnotification';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SmsnotificationsService {
  @Output() refresh = new EventEmitter<null>();
  constructor(private http: HttpClient) { }

  addSmsNotification(sms: SmsNotification) {
    return this.http.post(`${environment.baseURL}/NotificationBase`, sms);

  }


  addNotificationBase(body: {}) {
    return this.http.post(
      `${environment.baseURL}/NotificationBase`, body
    ).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        return error.errors[0];
        throwError('Something Went Wrong 🥲');
      })
    );
  }

  getNotificationBase() {
    return this.http.get(
      `${environment.baseURL}/NotificationBase`).pipe(
        map((data: any) => {
          return data.notificationBases;
        }),
        catchError((error) => {
          return throwError('Something Went Wrong 🥲');
        })
      );
  };

  editNotificationBase(body: {}, id: string) {
    return this.http.post(
      `${environment.baseURL}/NotificationBase/${id}/edit`, body
    ).pipe(
      map((data: any) => {
        return data;
      }),
      catchError((error) => {
        return throwError('Something Went Wrong 🥲');
      })
    );
  };

  deleteNotificationBase(id: string) {
    return this.http.post(
      `${environment.baseURL}/NotificationBase/${id}/delete`, {}
    ).pipe(
      map((data: any) => {
        console.log(data)
        return data;
      }),
      catchError((error) => {
        return throwError('Something Went Wrong 🥲');
      })
    );
  }

  getNotificationEvents() {
    return this.http.get(
      `${environment.baseURL}/NotificationEvents`).pipe(
        map((data: any) => {
          return data.notificationEvents;
        }),
        catchError((error) => {
          return throwError('Something Went Wrong 🥲');
        })
      );
  };

  getNotificationEventNameByEventId(id: string) {
    return this.http.get(
      `${environment.baseURL}/NotificationEvents`).pipe(
        map((data: any) => {
          const text = data.notificationEvents.filter((data: any) => { return data.notificationEventId == id });
          return text[0].notificationEventName;
        }),
        catchError((error) => {
          return throwError('Something Went Wrong 🥲');
        })
      );
  }
  editSmsNotification(sms: SmsNotification, smsId: string) {
    return this.http.post(
      `${environment.baseURL}/NotificationBase/${smsId}/edit`,
      sms
    );
  }
  deleteSmsNotification(smsId: string) {
    return this.http.post(
      `${environment.baseURL}/NotificationBase/${smsId}/delete`,
      {}
    );
  }
  getSmsNotification(branchId:string) {
    return this.http.get<SmsNotification[]>(`${environment.baseURL}/NotificationBase/${branchId}`).pipe(
      map(
        (data: SmsNotification[]) => {
          return data;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }
  getSmsNotificationEvent() {
    return this.http.get<SmsNotification[]>(`${environment.baseURL}/NotificationBaseEvent`).pipe(
      map(
        (data: SmsNotification[]) => {
          return data;
        },
        catchError((error) => throwError('Something Went Wrong'))
      )
    );
  }
}
