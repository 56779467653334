
<!-- 
<table class="table middle-border-table mt-4">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Icon</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of items">
        <td class="me-2">{{ item.itemNameEN }}</td>
        <td>
            <ng-icons [imgUrl]="item.imageUrl"></ng-icons>
        </td>
      </tr>
      <tr *ngIf="items.length === 0">
        <td colspan="2" class="text-muted">No items found</td>
      </tr>
    </tbody>
  </table>   -->
  <nb-card class="mt-4">
    <nb-card-header class="text-center">
      <!-- <h3>{{ categoryName }} - {{ subCategoryName }}</h3> -->
    </nb-card-header>
  
    <nb-card-body>
      <table class="table middle-border-table mt-4">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Icon</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of items">
            <td >{{ item.itemNameEN }}</td>
            <td>
              <ng-icons [imgUrl]="item.imageUrl" (click)="edit(item)"></ng-icons>
            </td>
          </tr>
          <tr *ngIf="items.length === 0">
            <td colspan="2" class="text-muted">No items found</td>
          </tr>
        </tbody>
      </table>
    </nb-card-body>
  </nb-card>
  