<nb-card>
  <div class="container">
    <div class="my-app-custom-plain-container-with-desc">
      <ng-container *ngFor="let img of images">
        <figure class="my-app-custom-image-with-desc">
          <!-- {{img.modal.img}} -->
          <img [src]="img.modal.img"
               (click)="openImageModalRowDescription(206, img)" (change)="change($event)"/>
          <input type="file" style="display:none" (change)="handle($event)" id="" accept="image/png, image/png, image/bmp" id="fileExplorer">
          <figcaption class="description">{{img.modal.description ? img.modal.description : 'No description available'}}
          </figcaption>
        </figure>
      </ng-container>
    </div>
  </div>
</nb-card>
