<nb-tabset fullWidth="false" responsive="true">
  <nb-tab tabTitle="Main" tabIcon="globe-2-outline">
    <ng-template lazyActivatable>
      <app-main-branch-tab></app-main-branch-tab>
    </ng-template>
  </nb-tab>

  <nb-tab tabTitle="Items" tabIcon="grid-outline">
    <ng-template lazyActivatable>
      <div class="pt-4">
        <app-branch-items [branchID]="id"></app-branch-items>
      </div>
    </ng-template>
  </nb-tab>

  <nb-tab tabTitle="Coupons" tabIcon="credit-card-outline">
    <ng-template lazyActivatable>
      <nb-card>
        <nb-card-body class="py-4">
          <app-coupon-branch-tab></app-coupon-branch-tab>
        </nb-card-body>
      </nb-card>
    </ng-template>
  </nb-tab>

  <nb-tab tabTitle="Trx & Orders" tabIcon="layers-outline">
    <ng-template lazyActivatable>
      <app-trxs-branch-tab></app-trxs-branch-tab>
    </ng-template>
  </nb-tab>

  <nb-tab tabTitle="POS" tabIcon="smartphone-outline">
    <ng-template lazyActivatable>
      <app-pos-branch-tab></app-pos-branch-tab>
    </ng-template>
  </nb-tab>

  <nb-tab tabTitle="Customers" tabIcon="people-outline">
    <ng-template lazyActivatable>
      <app-customers></app-customers>
    </ng-template>
  </nb-tab>
  <nb-tab tabTitle="Operations" tabIcon="settings-2-outline">
    <ng-template lazyActivatable>
      <app-operations [branchId]="id"></app-operations>
    </ng-template>
  </nb-tab>
  <nb-tab tabTitle="Table" tabIcon="square-outline">
    <ng-template lazyActivatable>
      <app-table [branchId]="id"></app-table>
    </ng-template>
  </nb-tab>

  <nb-tab tabTitle="Expenses" tabIcon="file-text-outline">
    <ng-template lazyActivatable>
      <app-expenses-list [branchId]="id"></app-expenses-list>
    </ng-template>
  </nb-tab>
  <!-- permissions and roles -->
  <nb-tab tabTitle="Users & Roles" tabIcon="people-outline">
    <ng-template lazyActivatable>

      <nb-tabset fullWidth="false" responsive="true">

        <nb-tab tabTitle="Users" tabIcon="people-outline">
          <ng-template lazyActivatable>
            <app-users-list [branchId]="id"></app-users-list>
          </ng-template>
        </nb-tab>
        
        <nb-tab tabTitle="Roles" tabIcon="settings-outline">
          <ng-template lazyActivatable>
            <app-roles-list [branchId]="id"></app-roles-list>

          </ng-template>
        </nb-tab>
      </nb-tabset>
    </ng-template>
  </nb-tab>

  <nb-tab tabTitle="Delivery Management" tabIcon="car-outline">
    <ng-template lazyActivatable>
      <app-delivery-management [branchID]="id"></app-delivery-management>
    </ng-template>
  </nb-tab>
  <!-- <nb-tab tabTitle="Workers" tabIcon="people-outline">
    <ng-template lazyActivatable>
      <app-workers></app-workers>
    </ng-template>
  </nb-tab> -->
</nb-tabset>
<!-- <nb-route-tabset [tabs]="tabs" fullWidth="false" responsive="true"></nb-route-tabset> -->
