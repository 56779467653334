
<div class="row">
  <form [formGroup]="manageCreditForm" class="row">
    <div class="col-6 mb-3">
      <label class="mb-2">Credit Time Limit (Days): </label>
      <input type="number" formControlName="creditTimeLimit" nbInput fullWidth placeholder="Enter Number of (Days)">
      <small class="text-danger" *ngIf="manageCreditForm.controls.creditTimeLimit.dirty && !manageCreditForm.controls.creditTimeLimit.valid  &&  manageCreditForm.controls.creditTimeLimit.errors?.['min']">
        min value is 0.
      </small>
      <small class="text-danger" *ngIf="manageCreditForm.controls.creditTimeLimit.dirty && !manageCreditForm.controls.creditTimeLimit.valid  &&  manageCreditForm.controls.creditTimeLimit.errors?.['required']">
        Credit Time Limit is required.
      </small>
    </div>
    <div class="col-6 mb-3">
      <label class="mb-2">Credit Max Amount (Currency): </label>
      <input type="number" formControlName="creditMaxAmount" nbInput fullWidth placeholder="Enter Number of (Days)">
      <small class="text-danger" *ngIf="manageCreditForm.controls.creditMaxAmount.dirty && !manageCreditForm.controls.creditMaxAmount.valid  &&  manageCreditForm.controls.creditMaxAmount.errors?.['min']">
        min value is 0.
      </small>
      <small class="text-danger" *ngIf="manageCreditForm.controls.creditMaxAmount.dirty && !manageCreditForm.controls.creditTimeLimit.valid  &&  manageCreditForm.controls.creditMaxAmount.errors?.['required']">
        Credit Max Amount is required.
      </small>
    </div>
    <div class="mb-1 col-12 d-flex justify-content-end">
      <button nbButton status="success" (click)="onSave()" [disabled]="!manageCreditForm.dirty || !manageCreditForm.valid">Save<nb-icon icon="save-outline"></nb-icon></button>
    </div>
    <small class="text-danger d-block text-end" *ngIf="!manageCreditForm.valid">there is one or more validation errors</small>
  </form>
</div>