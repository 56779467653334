import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { AreasService } from '../../../services/areas.service';

@Component({
  selector: 'app-delete-area',
  templateUrl: './delete-area.component.html',
  styleUrl: './delete-area.component.scss'
})
export class DeleteAreaComponent implements OnInit {
  branchAreaId:any;
  branchId:any;


  constructor(
    private nbToastr: NbToastrService,
    private areasService : AreasService,
    protected dialogRef: NbDialogRef<DeleteAreaComponent>
  ) { }
  ngOnInit(): void {

  }
  deleteArea(){
    console.log(this.branchAreaId);
    console.log(this.branchId);
    this.areasService.deleteBranchArea(this.branchAreaId,this.branchId)
    .subscribe(
      {
        next: res=>{
        
        this.nbToastr.show('Branch Area Deleted successfully.', 'success', {
          status: 'success',
          hasIcon: false,
          icon: 'checkmark-outline'
        });
        this.onClose('success'); 

        },
        error : err => {
          console.log(err)
          this.nbToastr.show('Cannot Delete Branch Area', 'error', {
            status: 'danger',
            hasIcon: false,
            icon: 'close-outline'
          });
        }
      });
    
      // this.areasService.refresh.emit();
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close()
  }

}
