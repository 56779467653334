import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { ItemsService } from '../../../services/items.service';

@Component({
  selector: 'app-edit-branch-items-img',
  templateUrl: './edit-branch-items-img.component.html',
  styleUrl: './edit-branch-items-img.component.scss'
})
export class EditBranchItemsImgComponent {
  @Input() item:any;
  @Output() updateDataEvent = new EventEmitter<void>();

  imageUrl: string | ArrayBuffer | null = null;
  fileToUpload: File | null = null;
  constructor( 
    private nbToastr: NbToastrService,
    private itemService: ItemsService,
    protected dialogRef: NbDialogRef<EditBranchItemsImgComponent>

  ) { }

  ngOnInit() {
    if (this.item && this.item.imageUrl ) {
      console.log('edit item');
      
      this.imageUrl = this.item.imageUrl;
    }
  }

   
    onDragOver(event: DragEvent) {
      event.preventDefault();
    }
  
    
    onDragLeave(event: DragEvent) {
      event.preventDefault();
    }
  
    
    onDrop(event: DragEvent) {
      event.preventDefault();
      if (event.dataTransfer && event.dataTransfer.files.length > 0) {
        this.fileToUpload = event.dataTransfer.files[0];
        this.previewImage(this.fileToUpload);
      }
    }
  
   
    onFileSelected(event: any) {
      if (event.target.files && event.target.files.length > 0) {
        this.fileToUpload = event.target.files[0];
        this.previewImage(this.fileToUpload!);
      }
    }
  
    
    previewImage(file: File) {
      const reader = new FileReader();
      reader.onload = () => {
        this.imageUrl = reader.result;
      };
      reader.readAsDataURL(file);
    }
  
    
    removeImage() {
      this.imageUrl = null;
      this.fileToUpload = null;
    }
  
    
  saveChanges() {
    if (this.fileToUpload) {
      let formData = new FormData();
      formData.append('logofile', this.fileToUpload!);
      console.log(this.fileToUpload);
      console.log(formData);

        this.itemService
        .uploadItemLogo(formData, this.item.itemId, this.item.itemNameEN)
        .subscribe({
          next:(res: any) => {
          console.log(res);
  
          this.nbToastr.success('Logo uploaded successfully','Success');
          this.updateDataEvent.emit();
          this.dialogRef.close({ updated: true });
          this.imageUrl = res.url;
            // this.placeholder = res.url;
  
            // console.log(res);
          },
          error:(err: any) => {
            console.log(err);
            this.nbToastr.danger(err.message);
          }
        });
      


    } 
    else {
      this.nbToastr.warning('No image selected', 'Warning');
    }
    // this.dialogRef.close(this.imageUrl); // Close the dialog after saving
  }
}
