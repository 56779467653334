<nb-card>
  <nb-card-header>
    <div class="d-flex align-items-center justify-content-between">
      <div class="filter d-flex gap-1">
        <button nbButton hero status="success" (click)="toggleFilter()">
          <nb-icon icon="funnel-outline"></nb-icon>
          {{ isFilterOn ? "Clear" : "Show" }} Filter
        </button>
      </div>
      <div>
        <!-- <label class="fs-tiny mx-2 text-secondary">Select Status To Filter</label> -->
        <nb-select placeholder="Select Status To Filter" hero status="basic" [(selected)]="filterByStatus" (selectedChange)="onStatusFilterSelectChange($event)"  style="min-width: 128px;">
          <nb-option *ngFor="let status of statuses; let i = index" [value]="status.name">{{status.name}}</nb-option>
        </nb-select>
        <button *ngIf="filterByStatus!=undefined" nbButton hero ghost (click)="resetStatusFilter()">
          <nb-icon icon="close-outline"></nb-icon>
        </button>

        <button *ngIf="role=='sales'" nbButton hero status="success" (click)="addNewRequest()">
          <nb-icon icon="plus-outline"></nb-icon> Add Creation Request
        </button>
      </div>
    </div>
    <div
      style="display: none; gap: 10px" class="mt-2" [class.d-flex]="isFilterOn">
      <input
        class="d-block"
        [(ngModel)]="filterDateRange"
        nbInput
        placeholder="Pick Date Range"
        [nbDatepicker]="rangepicker"
      />
      <nb-rangepicker #rangepicker [filter]="dateRangeFilter"></nb-rangepicker>
      <input
          type="text"
          nbInput
          placeholder="Enter branch name"
          [(ngModel)]="filterKeyword"
        />
      <button nbButton hero status="success"  (click)="filter()">Apply</button>
    </div>
  </nb-card-header>
  <nb-card-body>
    <!-- <div class="table-responsive" *ngIf="tables.length > 0"> -->
    <div class="table-responsive">
      <table
        class="table table-bordered"
        [nbSpinner]="false"
        nbSpinnerStatus="basic"
      >
        <thead>
          <tr class="text-center">
            <th scope="col">Branch Name</th>
            <th scope="col">Sales Man Name</th>
            <th scope="col">Created At</th>
            <th scope="col">Last Updated By</th>
            <th scope="col">Last Updated at</th>
            <th scope="col">Creation File</th>
            <th scope="col">Menu File</th>
            <th scope="col">Images 
              <!-- <br> <small style="font-weight: 400;font-size: 12px;">(Hover to inspect names)</small> -->
            </th>
            <th scope="col">Status</th>
            <!-- <th scope="col">Actions</th> -->
          </tr> 
        </thead>
        <tbody>
          <tr *ngFor="let req of filteredData; let i = index" class="text-center">
            <td>{{ req.branchName }}</td>
            <th>{{ req.salesManName }}</th>
            <td>{{ req.createdAt | date }}</td>
            <th>{{ req.lastUpdatedBy }}</th>
            <td>{{ req.lastUpdated | date }}</td>
           
            <td class="text-center">
              <button class="mx-md-1 my-2 my-md-0" placement="top" ngbTooltip="Download Creation File" nbButton status="warning" size="small" shape="round" hero 
              (click)="download(req.creationFileUrl,'CreationFile')">
              <nb-icon icon="download"></nb-icon>
              </button>
            </td>
            <td class="text-center">
              <button class="mx-md-1 my-2 my-md-0" placement="top" ngbTooltip="Download Menu File" nbButton status="warning" size="small" shape="round" hero 
              (click)="download(req.menuFileUrl,'MenuFile')">
                <nb-icon icon="download"></nb-icon>
              </button>
            </td>
            <td>
              @for (image of req.images; track $index) {
                <button class="mx-md-1 my-1" placement="top" ngbTooltip="Download {{image.name}}" nbButton status="warning" size="small" shape="round" hero 
                (click)="download(image.url,image.name)">
                  <nb-icon icon="download"></nb-icon>
                </button>
              }
            </td>
            <td>
              @if(role == 'sales'){
                {{req.status}}
              }
              @else {
                <nb-select size="small" [selected]="req.status"  [disabled]="role=='sales'" (selectedChange)="onSelectChange($event,req)" hero style="min-width: 128px;">
                  <nb-option *ngFor="let status of statuses; let i = index" [value]="status.name">{{status.name}}</nb-option>
                </nb-select>
              }
            </td>
            <!-- <td>               
              <button class="mx-md-1 my-2 my-md-0" placement="top" ngbTooltip="Delete" nbButton status="danger" size="small" shape="round" hero
                (click)="onDelete(req)">
                <nb-icon icon="trash-2-outline"></nb-icon>
              </button>
            </td> -->
          </tr>
        </tbody>
      </table>
    </div>
    <div class="py-3 text-center" *ngIf="data.length == 0">
      <p>No available data to show</p>
    </div>
    <div class="d-flex justify-content-center" *ngIf="totalPagesCount > 1 && !isDirty">
      <nb-button-group ghost>
        <button nbButtonToggle hero (click)="loadPreviousPage()">Previous</button>
        <button nbButtonToggle value="">
          {{ filterOptions.page }} of {{ totalPagesCount }}
        </button>
        <button nbButtonToggle hero (click)="loadNextPage()">Next</button>
      </nb-button-group>
    </div>
    @if (role!='sales') {
      <button style="display: block;margin-left: auto;" nbButton status="success" [disabled]="(!isDirty)" (click)="onSaveChanges()">Save</button>
    }

  </nb-card-body>
</nb-card>