import { Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BuisnessService } from '../../../services/buisness.service';
import { CustomersService } from '../../../services/customers.service';
import { GetCustomerRes } from '../types/customers';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss'],
})
export class AddCustomerComponent implements OnInit {
  @Input() businessId: any;
  @Input() customer!: GetCustomerRes;

  addCustomerForm: FormGroup = new FormGroup({
    businessId: new FormControl(''),
    fullname: new FormControl('', Validators.required),
    phone: new FormControl('', [Validators.required]),
    email: new FormControl(''),
    gender: new FormControl(0, Validators.required),
    address: new FormGroup({
      street: new FormControl(''),
      cityId: new FormControl(0, Validators.required),
      location: new FormControl(''),
      district: new FormControl(''),
      flat: new FormControl(''),
      buildingName: new FormControl(''),
      completeAddress: new FormControl(''),
      addressId: new FormControl(0),
    }),
    creditMaxAmount: new FormControl(0,Validators.min(0)),
    creditTimeLimit: new FormControl(0,Validators.min(0)),
    allowedForCredit: new FormControl(false),
  });
  // Validators.pattern(/^(?:\+971|00971|0)?(?:50|51|52|55|56|2|3|4|6|7|9)\d{7}$/)]
  cities: any[] = [];
  countries: any[] = [];
  submitted = false;
  selectedCountry: any;
  selectedCity: any;
  constructor(
    private businessService: BuisnessService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<AddCustomerComponent>,
    private customersService: CustomersService
  ) {}

  ngOnInit(): void {
    this.onGetCountries();
    if (this.customer) {
      this.addCustomerForm.patchValue({
        fullname: this.customer.fullname,
        phone: this.customer.phoneNumber,
        email: this.customer.email,
        address: {
          street: this.customer.defaultAddress.street ?? '',
          cityId: this.customer.defaultAddress.city.id ?? 0,
          location: this.customer.defaultAddress?.location ?? '',
          district: this.customer.defaultAddress.district ?? '',
          flat: this.customer.defaultAddress.flat ?? '',
          buildingName: this.customer.defaultAddress.buildingName ?? '',
          completeAddress: this.customer.defaultAddress.fullAddress ?? '',
        },
        creditMaxAmount: this.customer.creditMaxAmount,
        creditTimeLimit: this.customer.creditTimeLimit,
        allowedForCredit: this.customer.allowedForCredit
      });

    }
  }

  onSave() {
    // if edit add custom customerId, else add businessId and create
    this.submitted = true;
    if (this.addCustomerForm.valid) {
      if (this.customer && this.customer.id) {
        this.addCustomerForm.get('businessId')?.setValue(this.businessId);
        let body = this.addCustomerForm.value;
        body.address.addressId = this.customer.defaultAddress.addressId;
        body.customerId = this.customer.id;
        body.isActive = true;
        console.log(this.addCustomerForm.value,'vs',body)
        this.customersService.editCustomer(body).subscribe({
          next:(res) => {
            this.dialogRef.close('success');
          },
          error:(err) => {
            console.log({ err });
            this.nbToastr.show(err.error.message, 'Error', {
              status: 'danger',
              hasIcon: false,
            });
          }
        }
        );
      } else {
        this.addCustomerForm.get('businessId')?.setValue(this.businessId);
        this.customersService
          .addCustomer(this.addCustomerForm.value)
          .subscribe({
            next: (res) => {
              this.dialogRef.close('success');
            },
            error: (err) => {
              console.log(err.error.errors);
              if (err.error.statusCode == 400) {
                let errormsg = err.error.title
                  ? err.error.title
                  : err.error.errors[0];
                this.nbToastr.show(errormsg, 'Error', {
                  status: 'danger',
                  hasIcon: true,
                });
              } else {
                this.nbToastr.show(err.error.message, 'Error', {
                  status: 'danger',
                  hasIcon: true,
                });
              }
            },
          });
      }
      this.submitted = false;
    }
    console.log(this.addCustomerForm.valid,this.addCustomerForm.value);
  }

  onCancel() {
    this.dialogRef.close();
  }

  onGetCountries() {
    this.businessService.getCountries().subscribe(
      (res) => {
        this.countries = res.countries;
        if (this.customer) {
          this.selectedCountry = this.countries.find((country) => {
            return country.id == this.customer.defaultAddress.city.countryId;
          });

          this.onGetCities(this.selectedCountry);
        }
      },
      (err) => console.log(err)
    );
  }

  onGetCities(country: any) {
    this.businessService.getCitiesByCountry(country.id).subscribe(
      (res) => {
        this.cities = res.cities;

        if (this.customer) {
          this.selectedCity = this.cities.find((city) => {
            return city.id == this.customer.defaultAddress.city.id;
          }).id;
        }
      },
      (err) => console.log(err)
    );
  }
}
