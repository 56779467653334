import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

import { UserService } from '../../services/user.service';
import { User } from '../../models/user.model';
import { BranchService } from '../../services/branch.service';
import { BuisnessService } from '../../services/buisness.service';
import { GetBranchRes } from '../users-and-roles/types/branch';
import { catchError, combineLatestWith, EMPTY } from 'rxjs';
import { UsersService } from '../users-and-roles/users.service';
import { PostUserRes } from '../users-and-roles/types/user';
import { RolesService } from '../users-and-roles/roles.service';
import { GetRoleRes } from '../users-and-roles/types/role';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss']
})
export class AddUserBranchComponent implements OnInit {

  businessId!:string;
  branchId:string = '';
  userName: string = '';
  email: string = '';
  password: string = '';
  confirmpassword: string = ''
  userList: User[] = [];
  phoneNumber: string = ''
  businessBranches: GetBranchRes[] = []
  submitted: boolean = false;
  addUserForm: FormGroup = new FormGroup({
    userName: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required,Validators.email]),
    phoneNumber: new FormControl('',[Validators.required,Validators.pattern(/^\+?\d{10,15}$/)]),
    roleId: new FormControl('', Validators.required),
    password: new FormControl('', [Validators.required,Validators.minLength(8),Validators.maxLength(100)]),
    businessId: new FormControl(this.businessId),
    accessibleBranches:new FormControl([],Validators.required)
  });
  // to be changed after adding new endpoint in the backend
  roles: GetRoleRes [] = [];
  showPassword = false;
  showconfirmPassword = false;
  selectsLoaded = false;

  constructor(
    private businessService: BuisnessService,
    private rolesService: RolesService,
    private usersService:UsersService,
    public userService: UserService,
    private nbToastr: NbToastrService,
    protected dialogRef: NbDialogRef<AddUserBranchComponent>
  ) { }

  ngOnInit(): void {
    if(!this.businessId){
      this.businessId = localStorage.getItem('businessId')!;
    }
    this.addUserForm.patchValue({
      userName: this.userName,
      email: this.email,
      password: this.password,
      phoneNumber: this.phoneNumber,
      businessId: this.businessId,
    })
    this.selectsLoaded = false;
    this.getSelectsValues();
    this.getUsers();
    this.userService.refresh.subscribe(res => this.getUsers());
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    }
    else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }
  getUsers() {
    this.userService.getUsersBranch(this.branchId).subscribe((res: any) => {
      this.userList = res;

    },
      (err) => {
        console.error({ err });
      });
  }

  getSelectsValues(){
    this.rolesService.getBusinessRoles(this.businessId)
    .pipe(
      combineLatestWith( this.businessService.getBuisnessBranches(this.businessId))
    )
    .subscribe(([roles,branches]:[any,any]) => {
      this.roles = roles
      this.businessBranches = (branches.branches) as GetBranchRes[]
      this.selectsLoaded = true
    })
    
  }

  submit(){
    if(this.addUserForm.invalid || this.addUserForm.value.password  != this.confirmpassword){
      this.submitted = true 
      Object.keys(this.addUserForm.controls).forEach(key => {
        this.addUserForm.get(key)!.markAsTouched();
      });
      return
    }


    if(this.addUserForm.value.password  === this.confirmpassword){
      this.usersService.createUser(this.addUserForm.value)
      .subscribe({
        next: res=>{
        // response is 
        //   {
        //     "accessiblePages": null,
        //     "accessibleBranches": [
        //         "3da3654f-8f01-4e47-98dd-074a9373a810",
        //         "1544711a-b3ea-4210-b2b2-f2738d5465d0"
        //     ],
        //     "role": "newRoleWithoutDescription",
        //     "roleId": null,
        //     "id": "ffb682e5-5982-42c3-f1b6-08dd1ccaa0c0",
        //     "userName": "user05",
        //     "email": "pisgoi@tempmailto.org",
        //     "phoneNumber": "+966556100605",
        //     "createdAt": "2024-12-15T08:55:02.9630452+03:00"
        // }
          this.dialogRef.close(true)
          this.nbToastr.show('User Added successfully.', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline'
          });
        },
        error: err =>{
          this.submitted = true
          this.nbToastr.show(err, 'error', {
            status: 'danger',
            hasIcon: false,
            icon: 'close-outline'
          });
        }
      }
      )
    }
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close();
  }

}