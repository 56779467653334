import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbMenuItem, NbMenuService, NbSidebarService } from '@nebular/theme';
import { Subject } from 'rxjs';
import { BranchCreationRequestsService } from '../../components/sales/branch-creation-request.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss'
})
export class HomeComponent implements OnInit {
  state: string = '';
  searchVal: any;
  recentBusinesses: any[] = [];
  onClose: Subject<boolean> = new Subject();
  params: any = {};
  items: NbMenuItem[] = [
    {
      title: 'Dashboard',
      link: '/home',
      queryParams: {},
      icon: 'home-outline',
    },
    {
      title: 'Business',
      link: '/home/business',
      icon: 'bar-chart-outline',
      skipLocationChange: false
    },
    {
      title: 'Printing Layout',
      link: '/home/printing-layouts',
      icon: 'file-text-outline',
    },
    {
      title: 'Import / Export',
      link: '/home/import-export',
      icon: 'flip-2-outline',
      hidden: true,
    },
    {
      title: 'POS',
      link: '/home/pos',
      icon: 'smartphone-outline',
    },
    {
      title: 'New Branch',
      link: '/home/new-branch',
      icon: 'plus-outline',
    },
    // {
    //   title: 'Feature',
    //   link: '/home/feature',
    //   icon: 'award-outline',
    // },
  ];
  itemsDesigner: NbMenuItem[] = [
    {
      title: 'Dashboard',
      link: '',
      queryParams: {},
      icon: 'home-outline',
    },
    {
      title: 'Reference',
      link: '/home/reference',
      icon: 'book-outline',
    },
    {
      title: 'Branches',
      link: '/home/branches',
      icon: 'layers-outline',
    }
  ];
  role: string = "";
  user: string = "";
  id: string = "";

  pendingBranchCreationRequestsCount= 0;
  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private authService: NbAuthService,
    private route: ActivatedRoute,
    private router: Router,
    private branchCreationService: BranchCreationRequestsService
  ) { }

  ngOnInit() {
    this.branchCreationService.getPendingRequestsCount().subscribe(
      {
        next:(res) => this.pendingBranchCreationRequestsCount = res.data,
        error:console.error
      }
    )
    this.authService.onTokenChange().subscribe(res => {
      console.log(res.getPayload())
      this.user = res.getPayload().preferred_username;
      this.role = res.getPayload().role;
      this.id = res.getPayload().userId;
    });
    if (this.role=='designer') {
      this.router.navigate(['home/reference']);
    }
    else if(this.role=='sales'){
      this.router.navigate(['sales'])
    }
   }

  onToggle() {
    this.sidebarService.toggle(false, 'sidebar');
  }

  onStateChange() {
    this.sidebarService.getSidebarState('sidebar').subscribe(res => {
      this.state = res
    });
  }

  closeMenu() {
    this.onClose.next(false);
  }
}