<nb-card class="m-0 modal-container">
  <nb-card-header>
    <h5>Add Exception Hours</h5>
  </nb-card-header>
  <nb-card-body>
  <form [formGroup]="form">
    <div class="mb-3 col-12 ">
      <label class="mb-2 d-block">Reason :</label>
      <input type="text" formControlName="reason"  nbInput fullWidth placeholder="Optional reason">
    </div>
   
    <div class="mb-3 col-12">
      <label class="mb-2 d-block">Valid From Date</label>
      <input formControlName="fromDate" placeholder="Pick From Date" [nbDatepicker]="datePick" nbInput fullWidth>
      <nb-datepicker #datePick></nb-datepicker>
    </div>
    
    <div class="mb-3 col-12">
      <label class="mb-2 d-block">Valid To Date</label>
      <input formControlName="toDate" placeholder="Pick From Date" [nbDatepicker]="datePick1" nbInput fullWidth>
      <nb-datepicker #datePick1></nb-datepicker>
    </div>

    <div class="mb-3 col-12">
      <label class="mb-2 d-block">Start Of Work</label>
      <input formControlName="from" nbInput type="text" class="custom-time-picker" [nbTimepicker]="timepicker" fullWidth/>
      <nb-timepicker #timepicker="nbTimepicker" twelveHoursFormat></nb-timepicker>
    </div>
    <div class="mb-3 col-12">
      <label class="mb-2 d-block">End Of Work</label>
      <input formControlName="to" nbInput type="text" class="custom-time-picker" [nbTimepicker]="timepicker1" fullWidth/>
      <nb-timepicker #timepicker1="nbTimepicker" twelveHoursFormat></nb-timepicker>
    </div>
    <div class="mb-3 col-12">
      <nb-toggle labelPosition="left" formControlName="isAllDayClosed" class="mb-2 d-block">The branch is closed these days</nb-toggle>
    </div>
  </form>
  </nb-card-body>
  <nb-card-footer >
    <div class="d-flex justify-content-end">
      <button nbButton status="basic" class="d-inline-block ml-auto" (click)="onCancel()">Cancel</button>
      <button nbButton status="success" (click)="onSubmit()" [nbSpinner]="isSubmitting" >Save</button>
    </div>
  </nb-card-footer>

</nb-card>
