import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable } from 'rxjs';
import { GetCustomerRes } from '../components/customers/types/customers';

@Injectable({
  providedIn: 'root'
})
export class CustomersService {

  constructor(private http: HttpClient) { }

  getCustomers(businessId: string):Observable<GetCustomerRes[]> {
    return this.http.get<GetCustomerRes[]>(`${environment.baseURL}/Customer/GetAll?businessId=${businessId}`);
  }

  addCustomer(body: any) {
    return this.http.post(`${environment.baseURL}/Customer/Create`, body);
  }

  editCustomer(body: any) {
    return this.http.put(`${environment.baseURL}/Customer/Update`, body);
  }

  deleteCustomer(id: string) {
    return this.http.delete(`${environment.baseURL}/Customer/Delete?customerId=${id}`);
  }
}
