import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { ReferenceUploadImgComponent } from './reference-upload-img/reference-upload-img.component';

@Component({
  selector: 'app-reference-data-table',
  templateUrl: './reference-data-table.component.html',
  styleUrl: './reference-data-table.component.scss'
})
export class ReferenceDataTableComponent implements OnInit,OnChanges {

  @Input() data:any[] =[]
  @Input() BusinessTypeId:any;
  @Input() isCategory=false;
  @Input() isSubCategory=false;
  @Input() isitem=false;
  @Input() searchQuery:any;
  @Output() updateParentEvent = new EventEmitter<void>();


  alldata:any[] =[]
  

 constructor (
  private dialogService: NbDialogService

 ) { }
  ngOnInit(): void {
    console.log('ReferenceDataTableComponent ngOnInit');
    this.logValues();    
  }
 ngOnChanges(changes: SimpleChanges): void {
    console.log('ReferenceDataTableComponent ngOnChanges');
    this.logValues();
    this.alldata=[...this.data];
    this.filterCategories(this.searchQuery);

  }
  filterCategories(searchQuery:any){
    console.log(this.alldata);
    if (searchQuery=='') {
      
    }
    else{
    this.alldata = this.alldata.filter(
      c => c.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }
  }
  uploadImg(item:any){
    this.logValues();
    if (this.isCategory) {
      console.log('isCategory');
      console.log(item);
      const modalRef = this.dialogService.open(ReferenceUploadImgComponent, {
        context: {
          item:item,
          isCategory:true,
          BusinessTypeId:this.BusinessTypeId
        },
      });
      modalRef.onClose.subscribe((res: any) => {
        console.log(res);
        if (res?.updated) {
          this.updateParentEvent.emit();
        }  
      });
      
    }
    else if (this.isSubCategory) {
      console.log('isSubCategory');
      console.log(item);
      const modalRef = this.dialogService.open(ReferenceUploadImgComponent, {
        context: {
          item:item,
          isSubCategory:true,
          BusinessTypeId:this.BusinessTypeId
        },
      });
      modalRef.onClose.subscribe((res: any) => {
        console.log(res);
        if (res?.updated) {
          this.updateParentEvent.emit();
        }  
      });
      
    }
    else if (this.isitem) {
      console.log('isitem');
      console.log(item);
      const modalRef = this.dialogService.open(ReferenceUploadImgComponent, {
        context: {
          item:item,
          isitem:true,
          BusinessTypeId:this.BusinessTypeId
        },
      });
      modalRef.onClose.subscribe((res: any) => {
        console.log(res);
        if (res?.updated) {
          this.updateParentEvent.emit();
        }  
      });
    }
    else {
      // alert('no section chosen')
      console.error('no section chosen');
      
    }
    
    }
    removeimg(image:any){
      console.log(image);
      
    }
    
  logValues(){
    console.log('isCategory',this.isCategory);
    console.log('isSubCategory',this.isSubCategory);
    console.log('isitem',this.isitem);
    
  }
}
