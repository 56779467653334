import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreasService {
  @Output() refresh = new EventEmitter<null>();

  endpoint: string = '/Area';
  url: string = environment.baseURL + this.endpoint;

  constructor(private http: HttpClient) { }
  
  getCityArea(cityId:any){
    return this.http.get(this.url + `/CityAreas?cityId=${cityId}`);
  }
  getBranchAreas(branchId:any){
    return this.http.get(this.url + `/BranchAreas?branchId=${branchId}`);
  }
  createBranchArea(body:any){
    return this.http.post(this.url + `/Create/`,body);
  }
  editBranchArea(body:any){
    return this.http.patch(this.url + `/Edit/`,body);
  }
  deleteBranchArea(branchAreaId:any,branchId:any){
    return this.http.delete(this.url + `/Delete?id=${branchAreaId}&branchId=${branchId}`);
  }

}
