import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { UserService } from '../../../services/user.service';
import {
  ConfirmResetPasswordReq,
  GetUserRes,
} from '../../users-and-roles/types/user';
import {
  EmailValidator,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { UsersService } from '../../users-and-roles/users.service';
import { isReactive } from '@angular/core/primitives/signals';

@Component({
  selector: 'app-reset-password-user',
  templateUrl: './reset-password-user.component.html',
  styleUrls: ['./reset-password-user.component.scss'],
})
export class ResetPasswordUserBranchComponent implements OnInit {
  user!: GetUserRes;
  confirmPassword: string = '';
  confirmResetPasswordForm :FormGroup = new FormGroup({
    email: new FormControl('', Validators.required),
    code: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
  });
  showPassword = true;
  showconfirmPassword = true;

  constructor(
    public usersService: UsersService,
    protected dialogRef: NbDialogRef<ResetPasswordUserBranchComponent>,
    private nbToaster: NbToastrService
  ) {}

  ngOnInit(): void {
    this.confirmResetPasswordForm.patchValue({
      email:this.user.email,
    })
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    } else {
      return 'password';
    }
  }
  getInputType1() {
    if (this.showconfirmPassword) {
      return 'text';
    } else {
      return 'password';
    }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
  toggleShowPassword1() {
    this.showconfirmPassword = !this.showconfirmPassword;
  }

  restPassword() {
    if(this.confirmResetPasswordForm.invalid){
      return
    }
    if (!this.confirmResetPasswordForm.value.code) {
      this.nbToaster.show('Code can not be empty!', 'success', {
        status: 'success',
        hasIcon: false,
        icon: 'checkmark-outline',
      });
    } 
    else {
      if (this.confirmResetPasswordForm.value.password === this.confirmPassword) {
        this.usersService
          .confirmResetPassword(this.confirmResetPasswordForm.value)
          .subscribe({
            next: (res: any) => {
              // res is {result = 'success'}
              this.nbToaster.show(
                'User Password Reset successfully.',
                'success',
                {
                  status: 'success',
                  hasIcon: false,
                  icon: 'checkmark-outline',
                }
              );
              this.dialogRef.close();
            },
            error: (err) => {
              this.nbToaster.danger(err ,'error');
            },
          });
      } 
      else if (this.confirmResetPasswordForm.value.password != this.confirmPassword) {
        this.nbToaster.show(
          'password and confirm password  do not match',
          'error',
          {
            status: 'danger',
            hasIcon: false,
            icon: 'close-outline',
          }
        );
      }
    }
  }
  cancel() {
    this.dialogRef.close();
  }
}
