import { Component, OnInit } from '@angular/core';
import { AddCustomerComponent } from './add-customer/add-customer.component';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BranchService } from '../../services/branch.service';
import { CustomersService } from '../../services/customers.service';
import { GetCustomerRes } from './types/customers';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss'],
})
export class CustomersComponent implements OnInit {
  loading = false;
  customers: GetCustomerRes[] = [];
  businessId: string = '';
  constructor(
    private branchService: BranchService,
    private dialogService: NbDialogService,
    private nbToastr: NbToastrService,
    private customersService: CustomersService
  ) {}

  ngOnInit(): void {
    this.getBrnachBusinessId();
  }

  onAddCustomer() {
    const modalRef = this.dialogService.open(AddCustomerComponent, {
      context: {
        businessId: this.businessId,
      },
      hasBackdrop: true,
      autoFocus: true,
      closeOnBackdropClick: true,
      dialogClass: 'center-modal',
      hasScroll: true,
    });
    modalRef.onClose.subscribe((res) => {
      if (res == 'success') {
        this.getCustomers();
        this.nbToastr.show('cutomer created successfully', 'success', {
          status: 'success',
          hasIcon: true,
          icon: 'checkmark-outline',
        });
      }
    });
  }

  onEdit(customer: GetCustomerRes) {
    const modalRef = this.dialogService.open(AddCustomerComponent, {
      context: {
        customer: customer,
      },
      dialogClass: 'center-modal',
      hasScroll: true,
    });
    modalRef.onClose.subscribe((res) => {
      if (res == 'success') {
        this.getCustomers();
        this.nbToastr.show(res.message, 'success', {
          status: 'success',
          hasIcon: true,
          icon: 'checkmark-outline',
        });
      }
    });
  }

  onDelete(id: string) {
    this.customersService.deleteCustomer(id).subscribe({
      next: (res) => {
        this.nbToastr.show('customer Deleted', 'success', {
          status: 'success',
          hasIcon: false,
          icon: 'checkmark-outline',
        });

        this.getCustomers();
      },
      error: (err) => {
        this.nbToastr.show(err.error.Message, 'Error', {
          status: 'danger',
          hasIcon: false,
          icon: 'close-outline',
        });
      },
    });
  }

  getCustomers() {
    this.customersService.getCustomers(this.businessId).subscribe((res) => {
      console.log(res);
      this.customers = res;
    });
  }

  getBrnachBusinessId() {
    if (JSON.parse(localStorage.getItem('searchVal')!).type == 'branch') {
      let branchId = JSON.parse(localStorage.getItem('searchVal')!).id;
      this.branchService
        .getBusinessByBranchId(branchId)
        .subscribe((res: any) => {
          this.businessId = res.branch.businessId;
          this.getCustomers();
        });
    } else {
      this.businessId = JSON.parse(
        localStorage.getItem('searchVal')!
      ).businessId;
      this.getCustomers();
    }
  }
}
