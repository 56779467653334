import { Component, OnDestroy, OnInit } from '@angular/core';
import { BranchCreationRequestTableComponent } from '../branch-creation-request-table/branch-creation-request-table.component';
import { BranchCreationRequestsService } from '../branch-creation-request.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { PostBranchCreationRequest } from '../types/branch-creation-request';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-branch-creation-request-modal',
  templateUrl: './add-branch-creation-request-modal.component.html',
  styleUrl: './add-branch-creation-request-modal.component.scss',
})
export class AddBranchCreationRequestModalComponent implements OnInit, OnDestroy {


  branchName?:string;
  creationFile?:File;
  menuFile?:File;
  branchLogo?:File;
  defaultBranchLogo?:File;
  printingLogo?:File;
  footerLogo?:File;
  
  submitted = false;

  subs?:Subscription
  constructor(
    private branchCreationService: BranchCreationRequestsService,
    private dialogRef: NbDialogRef<AddBranchCreationRequestModalComponent>,
    private nbToaster: NbToastrService
  ) {}
  ngOnInit(): void {}
  ngOnDestroy(): void {
    this.subs?.unsubscribe();
  }

  onCreationFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.creationFile = input.files[0];
    }
  }
  onMenuFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.menuFile = input.files[0];
    }
  }
  onBranchLogoFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.branchLogo = input.files[0];
    }
  }
  onDefaultLogoFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.defaultBranchLogo = input.files[0];
    }
  }
  onPrintingFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.printingLogo = input.files[0];
    }
  }
  onFooterFileChange(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.footerLogo = input.files[0];
    }
  }

  onSubmit(){
    if(this.creationFile == undefined || this.menuFile == undefined || this.branchName?.trim()=='' || this.branchName == undefined){
      this.submitted = true
      return;
    }
    this.branchCreationService.post({
      branchName:this.branchName,
      creationFile:this.creationFile,
      menuFile:this.menuFile,
      branchLogo:this.branchLogo,
      defaultLogo:this.defaultBranchLogo,
      printingLogo:this.printingLogo,
      footerLogo:this.footerLogo
    } as PostBranchCreationRequest).subscribe(
      {
        next:(res)=>{
          this.nbToaster.success('Created Successfully','Success')
          this.dialogRef.close(true)
        },
        error:(err) => {
          // console.log(err)
          this.nbToaster.danger('Failed to Create New Branch Request','Error')
          this.dialogRef.close(false)
        }
      }
    )
  }
  onCancel(){
    this.dialogRef.close(false)
  }
}
