 <div class="text-end">
    <div class="col-12">
      <button status="success" nbButton hero class="btn" (click)="addUser()">
        <nb-icon icon="plus-square-outline"></nb-icon>
        Add User
      </button>
    </div>
</div>
<div class="padding"></div>
<strong *ngIf="users == null" style="text-align: center">No Users Found!</strong>
<div class="table-responsive" *ngIf="users != null">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col">No</th>
        <th scope="col">Name</th>
        <th scope="col">Email</th>
        <th scope="col">Phone Number</th>
        <th scope="col">Role</th>
        <th scope="col">Options</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let user of users; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <th scope="row">{{ user.userName }}</th>
        <td>{{ user.email }}</td>
        <td>{{ user.phoneNumber }}</td>
        <td>{{ user.role }}</td>
        <td>
          <div class="row">
            <div class="col d-flex">
              <button nbButton placement="left" ngbTooltip="Reset User Password" tooltipClass="custom-tooltip"
                (click)="resetUserPassword(user)"
                 shape="round" size="small" status="success">
                <nb-icon icon="refresh-outline"></nb-icon>
              </button>
              <button *ngIf="userCanBeEdited(user)" nbButton hero placement="left" ngbTooltip="Edit User" tooltipClass="custom-tooltip"
                (click)="
                      editUser(user)"
                        shape="round" size="small" status="primary">
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
              <button nbButton placement="left" ngbTooltip="Delete User" tooltipClass="custom-tooltip"
                (click)="deleteUser(user.id)" shape="round" size="small" status="warning">
                <nb-icon icon="trash-outline"></nb-icon>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>