import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GetLoyaltySettingsRes, PutLoyaltySettingsReq } from './types/loyalty';
import { environment } from '../../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoyaltyService {

  constructor(private http:HttpClient) { }

  getLoyaltySettings(branchId:string) : Observable<GetLoyaltySettingsRes>{
    return this.http.get<GetLoyaltySettingsRes>(`${environment.baseURL}/BranchSettings/GetLoyaltySetting?branchId=${branchId}`)
    .pipe(catchError(this.handelError))
  }
  
  saveLoyaltySettings(request: PutLoyaltySettingsReq):Observable<any>{    
    return this.http.post<any>(`${environment.baseURL}/BranchSettings/ManageLoyaltySetting`,request)
    .pipe(catchError(this.handelError))
  }

  handelError(httpError: HttpErrorResponse) {
      let errMsg = '';
      console.log('error handler : LoyaltyService error', httpError)
      if (httpError.error instanceof ErrorEvent) {
        // client error (network error ...)
        errMsg = `Error: ${httpError.error.message}`;
      } else {
        // server error
        errMsg = `Server Error: ${httpError.message}`;
      }
      // this error will be received by the observers , as a observable error
      return throwError(() => errMsg);
    }
}
