<nb-card class="modal-container">
  <nb-card-header>
    <h5>Delete Role</h5>
  </nb-card-header>
  <nb-card-body>
    <div class="p-3">
      Are You sure you want to delete {{role.name}}?
    </div>
  </nb-card-body>
  <nb-card-footer >
    <div class="d-flex justify-content-end">
      <button nbButton status="basic" class="d-inline-block ml-auto" (click)="onCancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
      <button nbButton status="warning" (click)="onConfirm()"><nb-icon icon="trash-outline"></nb-icon> Delete</button>
    </div>
  </nb-card-footer>
</nb-card>
