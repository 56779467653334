<nb-card  [nbSpinner]="isLoading">
    <nb-card-header>
        <div class="row">
            <div class="col-md-4 mb-3">
                <input  nbInput placeholder="Enter Category Name"  [(ngModel)]="searchQuery"  fullWidth  
                />
            </div>
        </div>

    </nb-card-header>

    <nb-card-body>
                 <app-reference-data-table
                  [data]="categories"
                   [isCategory]="true"
                   [BusinessTypeId]="businessTypeId"
                    [searchQuery]="searchQuery"
                    (updateParentEvent)="updateevent()"
                    >
                 </app-reference-data-table> 
    </nb-card-body>


</nb-card>