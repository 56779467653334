import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { RolesService } from '../roles.service';
import { GetRoleRes } from '../types/role';
import { BuisnessService } from '../../../services/buisness.service';
import { BranchService } from '../../../services/branch.service';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Subscription, switchMap } from 'rxjs';
import { GetBranchRes } from '../types/branch';
import { AddRoleModalComponent } from '../add-role-modal/add-role-modal.component';
import { DeleteRoleModalComponent } from '../delete-role-modal/delete-role-modal.component';
import { EditRoleModalComponent } from '../edit-role-modal/edit-role-modal.component';

@Component({
  selector: 'app-roles-list',
  templateUrl: './roles-list.component.html',
  styleUrl: './roles-list.component.scss',
})
export class RolesListComponent implements OnInit, OnDestroy {
  @Input() branchId!: string | null;
  businessId!: string;
  roles: GetRoleRes[] = [];
  subscriptions: Subscription[] = [];

  constructor(
    private rolesService: RolesService,
    private branchService: BranchService,
    private nbToaster: NbToastrService,
    private dialogService: NbDialogService
  ) {}

  ngOnInit(): void {
    if (!this.businessId) {
      this.businessId = localStorage.getItem('businessId')!;
    }
    this.getBusinessIdAndRoles();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  getBusinessIdAndRoles() {
    if (!this.branchId) {
      this.nbToaster.danger(
        'Branch Id is not obtained try to refresh the page... if' +
          ' this error keeps showing up please report the issue.',
        'Error',
        { duration: 8_000 }
      );
      return;
    }

    this.subscriptions.push(
      this.branchService
        .getBusinessByBranchId(this.branchId)
        .pipe(
          switchMap((res: any) => {
            this.businessId = res.branch.businessId;
            return this.rolesService.getBusinessRoles(this.businessId);
          })
        )
        .subscribe((roles) => (this.roles = roles))
    );
  }

  getRoles() {
    if (!this.businessId) {
      this.getBusinessIdAndRoles();
      return;
    }
    this.rolesService
      .getBusinessRoles(this.businessId)
      .subscribe((res) => (this.roles = res));
  }

  addRole() {
    if (!this.businessId) {
      this.nbToaster.danger(
        'Business Id is not obtained wait a sec and try again... if' +
          ' this error keeps showing up please report the issue.',
        'Error',
        { duration: 8_000 }
      );
      return;
    }
    const dialogRef = this.dialogService.open(AddRoleModalComponent, {
      context: {
        businessId: this.businessId,
      },
    });

    this.subscriptions.push(
      dialogRef.onClose.subscribe((res: any) => {
        if (res && res === true) {
          this.getRoles();
        }
      })
    );
  }

  editRole(role: GetRoleRes) {
    const dialogRef = this.dialogService.open(EditRoleModalComponent, {
      context: {
        role: role,
      },
    });
    this.subscriptions.push(
      dialogRef.onClose.subscribe((res: any) => {
        if (res && res === true) {
          this.getRoles();
        }
      })
    );
  }

  deleteRole(role: GetRoleRes) {
    const dialogRef = this.dialogService.open(DeleteRoleModalComponent, {
      context: {
        role: role,
      },
    });
    this.subscriptions.push(
      dialogRef.onClose.subscribe((res: any) => {
        if (res && res === true) {
          this.getRoles();
        }
      })
    );
  }
}
