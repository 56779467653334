export type GetWorkingHoursResponse = WrappedResponse[]
export interface WrappedResponse {
  workTypeName:string;
  id: number;
  branchId: string;
  workType: 1;
  workingHours: WorkingHour[];
}
export interface WorkingHour {
  id: number;
  workDay: number;
  from: string;
  to: string;
  fromTime: string;
  fromType: string;
  toTime: string;
  toType: string;
  workDayName: string;
}

export const TimeType = {
  AM: 'AM',
  PM: 'PM',
};







export interface PostShiftsRequest {
  branchId: string;
  workType: 1;
  shifts: Shift[];
}
export interface Shift {
  workDay: number;
  from: string;
  fromType: string;
  to: string;
  toType: string;
}

export interface PostExceptionHoursRequest {
  "branchId": string,
  "workType": 1,
  // ISO Date format
  "fromDate": string,
  "toDate": string,
  "from": string,
  "fromType": string,
  "to": string,
  "toType": string,
  "isAllDayClosed": boolean,
  "reason"?: string //optional
}

export const WorkDays = {
  saturday:{dayValue:6,dayName:'Saturday'} as WorkDay,
  sunday:{dayValue:7,dayName:'Sunday'} as WorkDay,
  monday:{dayValue:1,dayName:'Monday'} as WorkDay,
  tuesday:{dayValue:2,dayName:'Tuesday'} as WorkDay,
  wednesday:{dayValue:3,dayName:'Wednesday'} as WorkDay,
  thursday:{dayValue:4,dayName:'Thursday'} as WorkDay,
  friday:{dayValue:5,dayName:'Friday'} as WorkDay,
}

export interface WorkDay{
  dayValue:number,
  dayName:string
}
export interface PutShiftRequest{

}