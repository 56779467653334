<nb-card class="m-0 modal-container">
  <nb-card-header>
      <h5>Add Role</h5>
  </nb-card-header>
  <nb-card-body>
      <form [formGroup]="postRoleForm">
          <div class="form-row">
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Role Name</label>
                <input type="text" nbInput formControlName="name" fullWidth placeholder="Enter Role's name" >
                <small class="text-danger" *ngIf="(!postRoleForm.controls.name.valid && postRoleForm.controls.name.touched)" >
                  role name is required
                </small>
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Role Description</label>
                <input type="text" formControlName="description" nbInput fullWidth placeholder="Optional Role Description " >
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Accessible Pages</label>
                <small>chose pages that users with this role can access</small>
                <nb-select  multiple placeholder="Select Accessible Pages for this role" fullWidth formControlName="accessiblePages">
                  <nb-option *ngFor="let page of allPages"  [value]="page.id">{{page.pageName}}</nb-option>
                </nb-select>
                <small class="d-block">Hint: you can type to filter </small>
                <small class="text-danger" *ngIf="!postRoleForm.controls.accessiblePages.value.length && postRoleForm.touched" >
                  chose at least one page to create role
                </small>
              </div>
          </div>
      </form>
  </nb-card-body>
  <nb-card-footer >
    <div class="d-flex justify-content-end">
      <button nbButton status="basic" class="d-inline-block ml-auto" (click)="onCancel()">
        <nb-icon icon="close-outline"></nb-icon>Cancel
      </button>
      <button nbButton status="success" (click)="onSubmit()">
        <nb-icon icon="save-outline"></nb-icon>Save
      </button>
    </div>
  </nb-card-footer>

</nb-card>
