
<nb-card>
  <nb-card-header>
    <h5 class="modal-title">Delete User </h5>
  </nb-card-header>
  <nb-card-body>
    Confirm Deleting User ?
  </nb-card-body>
  <nb-card-footer class="text-end">  
    <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
    <button nbButton  status="warning" (click)="deleteUser()"><nb-icon icon="save-outline"></nb-icon>Delete</button>
  </nb-card-footer>
</nb-card>