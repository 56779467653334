export interface GetBranchCreationRequestResponse {
  id: string;
  branchName: string;
  menuFileUrl: string;
  creationFileUrl: string;
  images: SimpleImageResponse[];
  salesManId: string;
  salesManName: string;
  lastUpdatedById: string;
  lastUpdatedBy: string;
  status: string;
  createdAt: string;
  lastUpdated: string;
}
export interface SimpleImageResponse {
  name: string;
  url: string;
}
export interface PostBranchCreationRequest {
  branchName: string;
  menuFile: File;
  creationFile: File;
  branchLogo: File;
  defaultLogo: File;
  printingLogo: File;
  footerLogo: File;
}
export const RequestStatus = [
  {
    name: 'pending',
    enumValue: 0,
  },
  {
    name: 'preparing',
    enumValue: 1,
  },
  {
    name: 'done',
    enumValue: 2,
  },
  {
    name: 'rejected',
    enumValue: 3,
  },
];
export interface EditBranchCreationRequest {
  id: string;
  status: number;
}

export interface GetPendingBranchCreationCountResponse {
  data: number;
  isSucceeded: boolean;
  errors: string[];
  message: string;
}
