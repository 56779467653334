import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { WorkingHoursService } from '../../../services/working-hours.service';
import { PostShiftsRequest, Shift, TimeType, WorkDays, WorkingHour } from '../types/working-hours';
import { catchError, EMPTY, Subscription } from 'rxjs';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { PostExceptionHoursModalComponent } from '../post-exception-hours-modal/post-exception-hours-modal.component';

// this type is only used inside this component
// not suppose to be used outside it ( not used in api endpoints )
type LocalShift = {
  from:Date,
  to:Date,
  workDay:number
}

type LocalDay = {
  dayName:string,
  workDay:number,
  shifts:LocalShift[]
}
const HOUR_MS = 3_600_000;
@Component({
  selector: 'app-working-hours-management',
  templateUrl: './working-hours-management.component.html',
  styleUrl: './working-hours-management.component.scss',
})
export class WorkingHoursManagementComponent implements OnInit,OnDestroy {

  @Input() branchId: any;
  value: Date = new Date();
  workingHours!: WorkingHour[];
  subscriptions: Subscription[] = [];
  workDays = WorkDays;
  isLoading = true;
  isDirty = false;
  
  localDays:LocalDay[]=[] // should contain the week days (to avoid multiple filtering on the on local shifts list)
  localShifts:LocalShift[]=[]


  constructor(
    private workingHoursService: WorkingHoursService,
    private toastr:NbToastrService,
    private dialogService: NbDialogService ) {}
  
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe())
  }

  ngOnInit(): void {

    if (this.branchId) {
      this.loadWorkingHours();
    } else {
      this.toastr.warning("Something Went Wrong, try reloading the page.","Error",{duration:3000})
      console.error('this component should get the branch id');
    }
    
    // initialize the localDays array 
    Object.values(this.workDays).forEach(v => {
      this.localDays.push({
        workDay:v.dayValue,
        dayName:v.dayName,
        shifts:[]
      })
    });
  }

  loadWorkingHours() {
    
    this.subscriptions.push(
      this.workingHoursService
        .getWorkingHours(this.branchId!)
        .subscribe((res) => {
          
          this.workingHours = res[0]!.workingHours;
          this.isLoading = false;

          // map to local list
          this.workingHours.forEach(wh => {
            this.localDays.find(ld => ld.workDay == wh.workDay)?.shifts.push(({
              workDay:wh.workDay,
              from:this.convertFormattedTime2Date([wh.fromTime,wh.fromType]),
              to:this.convertFormattedTime2Date([wh.toTime,wh.toType])
            } as LocalShift))
          });
        })
    );
  }

  convertDate2FormattedTime(date: Date): [string, string] {
    
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let type = TimeType.AM;

    // AM or PM
    if (hours >= 12) {
      type = TimeType.PM;
    }
    
    // if the hours > 12 => reminder is the hours value and PM already detected 
    // if the hours < 12 and 00(12am) reminder==0 => falsy value => 12 is set for the 12 am  
    hours = hours % 12 || 12; 

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return [`${formattedHours}:${formattedMinutes}`, type];
  }

  convertFormattedTime2Date(hhmm_t: [string, string]): Date {
    
    let date = new Date();
    let [hoursStr, minutesStr] = hhmm_t[0].split(':');
    let hours = parseInt(hoursStr, 10);
    let minutes = parseInt(minutesStr, 10);
    
    const period = hhmm_t[1].toUpperCase();
    
    if (period === 'PM' && hours !== 12) {
      hours += 12; // Convert PM hours (e.g., 1 PM -> 13)
    } else if (period === 'AM' && hours === 12) {
      hours = 0; // Convert "12 AM" to "00" for midnight
    }
    
    date.setHours(hours);
    date.setMinutes(minutes);    
    return date;
  }

  isDayOn(day: LocalDay): boolean {
    
    if(this.isLoading){
      return false;
    }
    else{
      return day.shifts.length > 0
    }
  }

  addShift(day:LocalDay){
    
    this.isDirty = true;

    let now = new Date();
    now.setMinutes(0,0,0);

    day.shifts.push({
      workDay: day.workDay,
      from:now,
      to:new Date(now.getTime() + HOUR_MS),
    })
  }

  deleteShift(day:LocalDay,index:number){
    
    this.isDirty = true
    day.shifts.splice(index,1);
  }

  onToggleClick(checked:boolean,day:LocalDay){

    this.isDirty = true 

    if(checked && !this.isDayOn(day)){
      let now = new Date();
      now.setMinutes(0,0,0);

      day.shifts.push({
        workDay:day.workDay,
        from: now,
        to: new Date(now.getTime() + HOUR_MS),
      })
      return
    }
    // incase toggled to be unchecked => remove all shifts in that day
    day.shifts = []
  }

  clearLocalDaysShifts(){
    
    this.localDays.forEach(day => day.shifts = [])
  }

  onSave(){
    
    let allLocalShifts:LocalShift[] = []
    this.localDays.forEach(day => {
      allLocalShifts.push(...day.shifts)
    });

    const requestBody:PostShiftsRequest = {
      branchId:this.branchId,
      workType:1,
      shifts:allLocalShifts.map( lsh => {

        let [fromTime,fromType] = this.convertDate2FormattedTime(lsh.from)
        let [toTime,toType] = this.convertDate2FormattedTime(lsh.to)
        
        return {
          workDay:lsh.workDay,
          from:fromTime,
          fromType:fromType,
          to: toTime,
          toType:toType,
         } as Shift
      })
    }
    // post
    this.subscriptions.push(
      this.workingHoursService.postShifts(requestBody)
      .pipe(
        catchError(err => {
          this.toastr.danger("Saving Working Hours Failed.","Error")
          return EMPTY;
        })
      )
      .subscribe(res => {
        this.isLoading = true,
        this.isDirty = false,
        this.clearLocalDaysShifts();
        this.loadWorkingHours();
        this.toastr.success("Working Hours Changes Saved","Success")
      })
    )
  }

  onPostExceptionModalOpen(){
    this.dialogService.open(
      PostExceptionHoursModalComponent,
      {context:{branchId:this.branchId}})
      .onClose.subscribe( (res:boolean)=>{
        if(res){
          this.toastr.success("Exception Hours Successfully Configured.","Success")
        }
        else if(res == false){

          this.toastr.danger("Exception Hours Configuration Failed.","Error",{duration:5000})
        }
      })
  }
}
