import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor(private httpClient:HttpClient) {}

  sanitizeUrl(url:string|null):string|null{
    if(url == null) return null
    if(url!.startsWith("http")) return url
    return `http://${url}`
  }

  async isExist(url:string):Promise<boolean>{
    try{
      const response = await this.httpClient.get(url).toPromise()
      return true
    }catch(e){
      return false
    }
  }

}
