import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { catchError, Observable, throwError } from 'rxjs';
import { GetWorkingHoursResponse, PostExceptionHoursRequest, PostShiftsRequest } from '../components/working-hours/types/working-hours';

@Injectable({
  providedIn: 'root'
})
export class WorkingHoursService {
  baseUrl = environment.baseURL
  constructor(private http:HttpClient) { }

  getWorkingHours(branchId:string):Observable<GetWorkingHoursResponse>{

    return this.http.get<GetWorkingHoursResponse>(this.baseUrl+`/BranchShift/Get?branchId=${branchId}&workingType=1`)
    .pipe(
      catchError(this.handelError)
    )
  }

  postShifts(request:PostShiftsRequest):Observable<number>{

    return this.http.post<number>(this.baseUrl+`/BranchShift/Add`,request)
    .pipe(
      catchError(this.handelError)
    )
  }

  postExceptionHours(request:PostExceptionHoursRequest):Observable<number>{
    return this.http.post<number>(this.baseUrl + '/BranchShift/CreateExceptionHours',request)
    .pipe(
      catchError(this.handelError)
    )
  }


  handelError(httpError: HttpErrorResponse) {
    let errMsg = '';

    if (httpError.error instanceof ErrorEvent) {
      // client error (network error ...)
      errMsg = `Error: ${httpError.error.message}`;
    } else {
      // server error
      errMsg = `Server Error: ${httpError.message}`;
    }
    console.warn("error in working-hours-ser",errMsg)
    // this error will be received by the observers , as a observable error
    return throwError(() => errMsg);
  }
}
