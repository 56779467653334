<nb-card class="mb-0">
  <nb-card-header>
    <h5>Add New Layout</h5>
  </nb-card-header>
  <nb-card-body>
    <div class="row">
      <div class="col-4 col-md-4">
        <div class="row">
          <div class="mb-3 col-6">
            <label class="mb-2">Layout Type</label>
            <nb-radio-group [(ngModel)]="addLayout.layoutType" status="success" class="d-md-flex">
              <nb-radio [value]="1">Ticket</nb-radio>
              <nb-radio [value]="0">Token</nb-radio>
            </nb-radio-group>
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Layout Name</label>
            <input type="text" [(ngModel)]="addLayout.layoutName" nbInput fullWidth placeholder="Enter Layout Name" />
          </div>
          <div class="mb-3 col-12">
            <label class="mb-2">Layout Text</label>
            <div class="col-12">
              <div class="p-3">
                <nb-form-field>
                  <input type="text" nbInput fullWidth [(ngModel)]="filterKeyTerm" placeholder="Search Layout Key" />
                  <button nbSuffix nbButton ghost (click)="filterKeyTerm = ''">
                    <nb-icon icon="close-outline" pack="eva"> </nb-icon>
                  </button>
                </nb-form-field>
              </div>
              <span *ngFor="let layout of filterKeys(); let index = index">
                <span class="divider" *ngIf="
                    !newLayouts[index - 1] ||
                    layout.sectionName !== newLayouts[index - 1].sectionName
                  ">{{ layout.sectionName }}</span>
                <button status="basic" appearance="filled" shape="round" id="{{ layout.id }}"
                  (click)="addToLayout(layout)"  nbButton>
                  {{ layout.name }}
                </button>
                <span class="space"></span>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-5 col-md-5">
        <div class="p-2 panel" (drop)="drop($event)" (dragover)="allowDrop($event)">
          <div class="col-12">
            <span class="divider">Header</span>
            <div class="header">
              <div>
                <span *ngFor="let key of getAddedKeys('Header'); let index = index" >
                  <div class="added"
                  [draggable]="true"
                  id="{{ key.uuid }}" [attr.data-sort]="true" (dragstart)="drag($event, true); onDragStart(key.uuid)"
                  (dragenter)="onDragEnter(key.uuid, 'header')" >
                    <div class="close">
                      <button nbButton ghost status="danger" class="d-contents" (click)="deleteKey(key.uuid)">
                        <nb-icon icon="close-outline"></nb-icon>
                      </button>
                    </div>
                    <span style="min-width: 30%" *ngIf="!key.editable" (dblclick)="toggleEdit(key.uuid)">{{ key.name
                      }}</span>
                    <input *ngIf="key.editable" (keyup.enter)="toggleEdit(key.uuid)" (blur)="toggleEdit(key.uuid)"
                      [(ngModel)]="key.label" autofocus nbInput status="info" style="width: 50%" />
                    <button [id]="index + 'bold-btn'" nbButtonToggle
                      (click)="toggleBold(index); $event.stopPropagation()" [pressed]="key.options!.size == 2"
                      style="font-weight: bolder">
                      B
                    </button>
                    <nb-select size="small" placeholder="Align" [(ngModel)]="key.options!.align"
                     >
                      <nb-option value="0">Left</nb-option>
                      <nb-option value="1">Center</nb-option>
                      <nb-option value="2">Right</nb-option>
                    </nb-select>
                    <select [(ngModel)]="key.options!.align">
                      <option value="0">Left</option>
                      <option value="1">Center</option>
                      <option value="2">Right</option>
                    </select>
                  </div>
                  <div></div>
                </span>
              </div>
            </div>

            <span class="divider">Body</span>
            <div class="body">
              <div>
                <span *ngFor="let key of getAddedKeys('Body'); let index = index">
                  <div class="added" [draggable]="true" id="{{ key.uuid }}" [attr.data-sort]="true"
                    (dragstart)="drag($event, true); onDragStart(key.uuid)"
                    (dragenter)="onDragEnter(key.uuid, 'body')"
                    >
                    <div class="close">
                      <button nbButton ghost status="danger" class="d-contents" (click)="deleteKey(key.uuid)">
                        <nb-icon icon="close-outline"></nb-icon>
                      </button>
                    </div>
                    <span *ngIf="!key.editable" (dblclick)="allowLabelEdit(key.uuid)">{{ key.name }}</span>
                    <input *ngIf="key.editable" (keyup.enter)="toggleEdit(key.uuid)" (blur)="toggleEdit(key.uuid)"
                      [(ngModel)]="key.label" autofocus nbInput status="info" style="width: 100%" />
                  </div>
                  <div></div>
                </span>
              </div>
            </div>

            <span class="divider">Footer</span>
            <div class="layout-footer">
              <div>
                <span *ngFor="let key of getAddedKeys('Footer'); let index = index">
                  <div class="added"
                   [draggable]="true"
                  id="{{ key.uuid }}" [attr.data-sort]="true" (dragstart)="drag($event, true); onDragStart(key.uuid)"
                  (dragenter)="onDragEnter(key.uuid, 'footer')">
                  
                    <div class="close">
                      <button nbButton ghost status="danger" class="d-contents" (click)="deleteKey(key.uuid)">
                        <nb-icon icon="close-outline"></nb-icon>
                      </button>
                    </div>
                    <span *ngIf="!key.editable" (dblclick)="allowLabelEdit(key.uuid)">{{ key.name }}</span>
                    <input *ngIf="key.editable" (keyup.enter)="toggleEdit(key.uuid)" (blur)="key.editable = !key.editable" [(ngModel)]="key.label"
                      autofocus nbInput status="info" style="width: 100%" />
                    <nb-button-group multiple>
                      <button nbButtonToggle (pressed)="(key.options!.isBold)" class="option">
                        B
                      </button>
                    </nb-button-group>
                  </div>
                  <div></div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-3 col-md-3">
        <div class="p-2 panel" [innerHTML]="getLayoutPreview()"></div>
      </div>
    </div>
  </nb-card-body>
  <nb-card-footer class="text-right">
    <button nbButton status="success" (click)="onSave()">
      <nb-icon icon="save-outline"></nb-icon>Save
    </button>

    <button nbButton status="danger" (click)="onClose()">
      <nb-icon icon="close-outline"></nb-icon>Cancel
    </button>
  </nb-card-footer>
</nb-card>
