import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbRegisterComponent,
  NbLogoutComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './auth.guard';
import { SearchComponent } from './pages/search/search.component';
import { BusinessTabComponent } from './pages/business-tab/business-tab.component';
import { BranchTabComponent } from './pages/branch-tab/branch-tab.component';
import { MainBranchTabComponent } from './pages/main-branch-tab/main-branch-tab.component';
import { CouponBranchTabComponent } from './pages/coupon-branch-tab/coupon-branch-tab.component';
import { CouponBranchComponent } from './components/coupon-branch/coupon-branch.component';
import { BranchItemsComponent } from './components/branch-items/branch-items.component';
import { TrxsBranchTabComponent } from './pages/trxs-branch-tab/trxs-branch-tab.component';
import { PosBranchComponent } from './components/pos-branch/pos-branch.component';
import { BusinessComponent } from './components/business/business.component';
import { PosComponent } from './components/pos/pos.component';
import { PrintingLayoutsComponent } from './components/printing-layouts/printing-layouts.component';
import { ImportExportComponent } from './components/import-export/import-export.component';
import { NewBranchComponent } from './components/new-branch/new-branch.component';
import { FeatureComponent } from './components/feature/feature.component';
import { NewLayout2Component } from './components/printing-layouts/new-layout2/new-layout2.component';
import { EditLayoutComponent } from './components/printing-layouts/edit-layout/edit-layout.component';
import { ReferenceComponent } from './pages/reference/reference.component';
import { BranchesComponent } from './pages/branches/branches.component';
import { SalesComponent } from './pages/sales/sales.component';
import { BranchCreationRequestTableComponent } from './components/sales/branch-creation-request-table/branch-creation-request-table.component';
const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () =>
      import('./auth/auth.module').then((m) => m.NgxAuthModule),
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'search',
    component: SearchComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: BusinessTabComponent,
      },
      {
        path: 'branch-tab/:id',
        component: BranchTabComponent,
        children: [
          {
            path: '',
            component: MainBranchTabComponent,
          },
          {
            path: 'coupons-tab',
            component: CouponBranchTabComponent,
            children: [
              {
                path: 'coupon-details',
                component: CouponBranchComponent,
                data: { couponMasterId: '' },
              },
            ],
          },
          {
            path: 'items-tab',
            component: BranchItemsComponent,
          },
          {
            path: 'trxs-tab',
            component: TrxsBranchTabComponent,
          },
          {
            path: 'pos-tab',
            component: PosBranchComponent,
          },
        ],
      },
      {
        path: 'business',
        component: BusinessComponent,
      },
      {
        path: 'pos',
        component: PosComponent,
      },
      {
        path: 'printing-layouts',
        component: PrintingLayoutsComponent,
      },
      {
        path : 'printing-layouts/new-layout',
        component : NewLayout2Component,
      },
      {
        path : 'printing-layouts/edit-layout',
        component : EditLayoutComponent,
      },
      {
        path: 'import-export',
        component: ImportExportComponent,
      },
      {
        path: 'new-branch',
        component: NewBranchComponent,
      },
      {
        path: 'feature',
        component: FeatureComponent,
      },
      {
        path: 'reference',
        component: ReferenceComponent,
      },
      {
        path: 'branches',
        component: BranchesComponent,
      },
      {
        path: 'branch-creation',
        component: BranchCreationRequestTableComponent
      }
    ],
  },
  {
    path:'sales',
    component: SalesComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'',
        component:BranchCreationRequestTableComponent
      },
      {
        path:'Requests',
        component:BranchCreationRequestTableComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
