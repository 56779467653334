import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from '../../../environments/environment';
import { EditRoleReq, GetClientAppPageRes, GetRoleRes, PostRoleReq } from './types/role';

@Injectable({
  providedIn: 'root'
})
export class RolesService {

  constructor(private http :HttpClient) { }
  
  getAllRoles(){
    return this.http
    .get<any>(`${environment.baseURL}/Users/get-user-roles`)
    .pipe(
      map((data: any) => {
        return data.userRoles;
      }),
      catchError(this.handelError)
    );
  }

  getBusinessRoles(businessId:string):Observable<GetRoleRes[]>{
    return this.http.get<GetRoleRes[]>(`${environment.baseURL}/roles?businessId=${businessId}`)
    .pipe(
      catchError(this.handelError)
    )
  }

  postRole(request:PostRoleReq):Observable<any>{
    return this.http.post(`${environment.baseURL}/roles/create`,request)
    .pipe(
      catchError(this.handelError)
    )
  }

  editRole(request:EditRoleReq):Observable<any>{
    return this.http.patch(`${environment.baseURL}/roles/edit`,request)
    .pipe(
      catchError(this.handelError)
    )
  }

  deleteRole(roleId:string):Observable<any>{
    return this.http.delete(`${environment.baseURL}/roles/delete?roleId=${roleId}`)
    .pipe(
      catchError(this.handelError)
    )
  }

  getRoleAccessiblePages(roleId:string):Observable<GetClientAppPageRes[]>{
    return this.http.get<GetClientAppPageRes[]>(`${environment.baseURL}/roles/RolePermissions?roleId=${roleId}`)
    .pipe(
      catchError(this.handelError)
    )
  }

  getClientApplicationPages():Observable<GetClientAppPageRes[]>{
    return this.http.get<GetClientAppPageRes[]>(`${environment.baseURL}/Applications/ApplicationPages`)
    .pipe(
      catchError(this.handelError)
    )
  }

  handelError(httpError: HttpErrorResponse) {
    let errMsg = '';

    if (httpError.error instanceof ErrorEvent) {
      // client error (network error ...)
      errMsg = `Error: ${httpError.error.message}`;
    } else {
      // server error
      errMsg = `Server Error: ${httpError.message}`;
    }
    // this error will be received by the observers , as a observable error
    return throwError(() => errMsg);
  }
}
