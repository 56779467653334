<nb-card class="m-0 modal-container">
  <nb-card-header>
      <h5>Add Branch Creation Request</h5>
  </nb-card-header>
  <nb-card-body>
      <div>
          <div class="form-row">
              <div class="mb-3 col-12 ">
                  <label class="mb-2 d-block">
                    Branch Name 
                  </label>
                  <input nbInput type="text" [(ngModel)]="branchName" fullWidth placeholder="Branch Name">
                  <span class="text-danger"
                  *ngIf="(branchName?.trim()=='' || branchName == undefined) && submitted">
                    please enter branch name 
                  </span>
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Upload Creation File</label>
                <input type="file" accept=".xls, .xlsx" (change)="onCreationFileChange($event)" nbInput fullWidth >
                <span class="text-danger" *ngIf="submitted && creationFile == undefined">
                  creation file is required
                </span>
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Upload Menu File</label>
                <input type="file" accept=".xls, .xlsx" (change)="onMenuFileChange($event)" nbInput fullWidth >
                <span class="text-danger" *ngIf="submitted && menuFile == undefined">
                  menu file is required
                </span>
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Upload Branch Logo File</label>
                <input type="file" accept=".jpg, .jpeg, .png, .bmp, .webp" (change)="onBranchLogoFileChange($event)" nbInput fullWidth >
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Upload Default Logo File</label>
                <input type="file" accept=".jpg, .jpeg, .png, .bmp, .webp" (change)="onDefaultLogoFileChange($event)" nbInput fullWidth >
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Upload Printing Logo File</label>
                <input type="file" accept=".jpg, .jpeg, .png, .bmp, .webp" (change)="onPrintingFileChange($event)" nbInput fullWidth >
              </div>
              <div class="mb-3 col-12 ">
                <label class="mb-2 d-block">Upload Footer Logo File</label>
                <input type="file" accept=".jpg, .jpeg, .png, .bmp, .webp" (change)="onFooterFileChange($event)" nbInput fullWidth >
              </div>

          </div>
        </div>
  </nb-card-body>
  <nb-card-footer >
    <div class="d-flex justify-content-end">
      <button nbButton status="basic" class="d-inline-block ml-auto" (click)="onCancel()">Cancel</button>
      <button nbButton status="success"  (click)="onSubmit()">Save</button>
    </div>
  </nb-card-footer>

</nb-card>
