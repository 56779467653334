<div class="container mt-4">
    <div class="row justify-content-center">
        @if (isCategory) {
      <div class="col-md-2 col-sm-6 mb-4" *ngFor="let item of categories">
        <div class="card h-100 shadow-sm">
          <img [src]="item.imageUrl" class="card-img-top" (click)="editimg(item,true)" alt="{{ item.categoryNameEN }}">
          <div class="card-body text-center">
            <h5 class="card-title">{{ item.categoryNameEN }}</h5>
          </div>
        </div>
      </div>
    }
    @else {
        <div class="col-md-2 col-sm-6 mb-4" *ngFor="let item of subCategories">
            <div class="card h-100 shadow-sm">
              <img [src]="item.imageUrl" class="card-img-top" (click)="editimg(item,false)" alt="{{ item.subCategoryNameEN }}">
              <div class="card-body text-center">
                <h5 class="card-title">{{ item.subCategoryNameEN }}</h5>
              </div>
            </div>
          </div>
    }
    </div>
  </div>
  