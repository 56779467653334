import { Component, Input, OnInit } from '@angular/core';
import { BuisnessService } from "../../services/buisness.service";
import {
  GridLayout,
  ModalGalleryService,
  PlainGalleryStrategy,
  PlainGalleryConfig,
  ModalGalleryRef,
  Image, PlainLibConfig, LineLayout, ButtonType, ButtonsStrategy, ButtonEvent, ButtonsConfig
} from "@ks89/angular-modal-gallery";
import { DomSanitizer } from "@angular/platform-browser";
import { Subscription } from "rxjs";
import { HelperService } from "../../utils/helper.service";
import { environment } from "../../../environments/environment";

interface BranchLogos {
  logo?: string,
  defaultlogo?: string,
  printinglogo?: string,
  footerlogo?: string
}

enum LogoType {
  logo = "logo",
  defaultlogo = "defaultlogo",
  printinglogo = "printinglogo",
  footerlogo = "footerlogo"
}

@Component({
  selector: 'app-branch-icons',
  templateUrl: './branch-icons.component.html',
  styleUrls: ['./branch-icons.component.scss']
})
export class BranchIconsComponent implements OnInit {
  isLoading: boolean = false;
  branchLogosNames: any[] = [];
  imageFile: File | undefined;
  @Input() branchId: string = '';
  placeHolder: string = 'https://global-uploads.webflow.com/5e157547d6f791d34ea4e2bf/6087f2b060c7a92408bac811_logo.svg'
  images: Image[] = [];
  customButtonsConfig: ButtonsConfig = {
    visible: true,
    strategy: ButtonsStrategy.CUSTOM,
    buttons: [
      {
        className: 'fa fa-upload white',
        type: ButtonType.CUSTOM,
        ariaLabel: 'upload a new image',
        title: 'upload image',
        fontSize: '20px'
      },
      {
        className: 'fa fa-times-circle white',
        type: ButtonType.CLOSE,
        ariaLabel: 'custom close aria label',
        title: 'custom close title',
        fontSize: '20px'
      }
    ]
  };
  private buttonBeforeHookSubscription: Subscription | undefined;
  constructor(private businessService: BuisnessService, 
    private modalGalleryService: ModalGalleryService, 
    private sanitizer: DomSanitizer, private helper: 
    HelperService) {

  }

  change(e: any) {
    console.log({ e })
  }

  openImageModalRowDescription(id: number, image: Image): void {
    const index: number = BranchIconsComponent.getCurrentIndexCustomLayout(image, this.images);
    const dialogRef: ModalGalleryRef = this.modalGalleryService.open({
      id,
      images: this.images,
      currentImage: this.images[index],
      libConfig: { buttonsConfig: this.customButtonsConfig }
    }) as ModalGalleryRef;

    // required to enable ADD button
    this.buttonBeforeHookSubscription = dialogRef.buttonAfterHook$.subscribe((event: ButtonEvent) => {
      if (!event || !event.button) return;
      // Invoked after a click on a button, but before that the related
      // action is applied.

      if (event!.button.type === ButtonType.CUSTOM) {
        console.log('should upload a new image');
        let input = document!.getElementById("fileExplorer")
        input!.click()

        input!.addEventListener("change", (ev) => {
          let imageFile = (ev.target as HTMLInputElement)!.files![0];
          //TODO:Add loader starter
          console.log(event.image)
          this.uploadLogo(imageFile, event.image?.modal.title as LogoType, event.image?.id).subscribe((res: any) => {
            //TODO:Add loader end
            console.log({ res })
            this.getLogos()
          }, error => {
            //TODO:Add loader end
            console.error({ error })
          }, () => {
            //TODO:Add loader end
            input!.removeEventListener("change", (e) => {
              console.log("listener Destroyed")
            })
          })
        })


        setTimeout(() => {
          this.modalGalleryService.updateModalImages(this.images);
        }, 0);
      }
    });
  }

  static getCurrentIndexCustomLayout(image: Image, images: Image[]): number {
    return image ? images.indexOf(image) : -1;
  };

  ngOnInit(): void {
    this.getLogos()
  }

  ngOnDestroy(): void {
    // release resources to prevent memory leaks and unexpected behaviours
    if (this.buttonBeforeHookSubscription) {
      this.buttonBeforeHookSubscription.unsubscribe();
    }
  }

  uploadLogo(logo: File, logoType: LogoType, id: number | undefined) {
    let formData: FormData = new FormData();

    formData.append("ImageName", logoType);
    formData.append("ImageTypeId", JSON.stringify(id));
    formData.append("BranchId", this.branchId);
    formData.append("BusinessTypeId", "1");
    formData.append("File", logo);

    return this.businessService.uploadBranchLogos(formData);
  }

  handle(e: Event) {
    console.log({ e });
  }

  async getLogos() {
    this.isLoading = true;

    let logoUrl = `${environment.baseURL}/Branches/BranchLogo?branchId=${this.branchId}`;

    let img = new Image(1, {
      img: await this.helper.isExist(logoUrl) ? logoUrl : this.placeHolder,
      title: 'Logo',
      description: "Branch Logo"
    });

    this.images = [...this.images, img]

    let defaultLogoUrl = `${environment.baseURL}/Branches/BranchDefaultLogo?branchId=${this.branchId}`;

    img = new Image(2, {
      img: await this.helper.isExist(defaultLogoUrl) ? defaultLogoUrl : this.placeHolder,
      title: "Default Logo",
      description: "default Logo"
    });

    this.images = [...this.images, img]

    let printingLogoUrl = `${environment.baseURL}/Branches/BranchPrintLogo?branchId=${this.branchId}`;

    img = new Image(3, {
      img: await this.helper.isExist(printingLogoUrl) ? printingLogoUrl : this.placeHolder,
      title: "Printing Logo",
      description: "printing Logo"
    });

    this.images = [...this.images, img]


    let footerUrl = `${environment.baseURL}/Branches/BranchFooterLogo?branchId=${this.branchId}`
    img = new Image(4, {
      img: await this.helper.isExist(footerUrl) ? footerUrl : this.placeHolder,
      title: "Footer Logo",
      description: "footer Logo"
    });

    this.images = [...this.images, img]

  }
}
