import { Component, Input, OnInit } from '@angular/core';
import { BuisnessService } from '../../services/buisness.service';
import { ItemsService } from '../../services/items.service';
import { debounceTime } from 'rxjs';


@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrl: './branches.component.scss'
})
export class BranchesComponent implements OnInit {
  isLoading: boolean = false;

  searchQuery:any;
  filteredBusinesses: any[] = [];

  businesses: any[] = [];
  businessId: any;

  branches: any[] = [];
  branchId: any;

  categories: any[] = [];
  categoryId: any;

  subCategories: any[] = [];
  subCategoryId: any;

  items: any[] = [];
  itemId: any;


  constructor(
    private itemService: ItemsService,
    private businessService: BuisnessService,
  ) { 
  }

  ngOnInit(): void {
    this.businessService.getBuisnesses(false).subscribe(
      res => {
        console.log(res);
        if (res.isSucceeded) {
          this.businesses = res.businesses
          this.filteredBusinesses=this.businesses;
        }

      }
    );
  }

  getBuisnessBranches(business:any) {
    console.log(this.businessId);
    this.businessId=business.id;
    console.log(this.businessId);
    this.branchId = null; // or undefined or '' depending on your requirement
    this.categoryId =null;
    this.subCategoryId =null;
    this.categories=[];
    this.subCategories=[];
    this.items =[];
    if (this.businessId) {
      this.businessService.getBuisnessBranches(this.businessId).subscribe(
        (res: any) => {
          console.log(res);
          if (res.isSucceeded) {
            this.branches = res.branches
          }
        }
      );
    }
    else {
      console.log('no Buisness chosen');

    }

  }

  getbranchCategory() {
    console.log(this.branchId);
    this.categoryId=null;
    this.subCategoryId =null;
    this.categories=[];
    this.subCategories=[];
    this.items = [];
    if (this.branchId) {
      this.itemService.getCategories(this.branchId).subscribe(
        {
          next: (res: any) => {
            console.log(res);           
              this.categories = res;       
          },
          error: (err: any) => console.log(err)
        }
      );
    }


  }
  selectCategory(e:any){
    console.log(this.categoryId);
    this.subCategoryId=null;
    this.items = [];
    this.subCategories = this.categories
    .find(category => category.categoryId == this.categoryId)?.subCategories || [];
  }
  selectSubCategory(){
    console.log(this.subCategoryId);
    this.itemService.getItems(this.branchId).subscribe({
      next:(res: any) => {
      this.items = res;
      this.items = this.items.filter(i=>i.subCategoryId == this.subCategoryId);
      console.log(this.items);
      
    }
    ,error: err => console.log(err)
  });
  }
  
  filterBusinesses(value: string){
    this.filteredBusinesses = this.businesses.filter(
      b=>b.name.toLowerCase().includes(value.toLowerCase())
    );
  }
  selectOk(business:any){
    console.log('ok');
    console.log(this.businessId);
    this.businessId=business.id;
    console.log(this.businessId);
    
  }

  updateevent(){
    if (this.branchId) {
      this.itemService.getCategories(this.branchId).subscribe(
        {
          next: (res: any) => {
            console.log(res);           
              this.categories = res;
              if (this.categoryId) {
                console.log('categoryId',this.categoryId);
                
                this.subCategories = this.categories
                .find(category => category.categoryId == this.categoryId)?.subCategories || [];
                console.log(this.subCategories);
                this.selectSubCategory();
              }       
          },
          error: (err: any) => console.log(err)
        }
      );
    }

  }
}

