<table class="table middle-border-table mt-4">
    <thead>
      <tr>
        <th scope="col">Name</th>
        <th scope="col">Icons</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of alldata">
        <td >{{ item.name }}</td>
        <td class="icon-container">
          <ng-icons *ngFor="let image of item.imageURLs" [imgUrl]="image" (click)="removeimg(image)"></ng-icons>
          <nb-icon icon="plus-outline" class="rounded-icon" (click)="uploadImg(item)"></nb-icon>
        </td>
      </tr>
      <tr *ngIf="alldata.length === 0">
        <td colspan="2" class="text-muted">No data found</td>
      </tr>
    </tbody>
  </table>