import { Component, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { UsersService } from '../../users-and-roles/users.service';

@Component({
  selector: 'app-delete-user',
  templateUrl: './delete-user.component.html',
  styleUrls: ['./delete-user.component.scss'],
})
export class DeleteUserBranchComponent implements OnInit {
  userId!: string;
  constructor(
    public usersService: UsersService,
    private nbToaster: NbToastrService,
    protected dialogRef: NbDialogRef<DeleteUserBranchComponent>
  ) {}

  ngOnInit(): void {}

  deleteUser() {
    this.usersService.deleteUser(this.userId).subscribe(
      (res: any) => {
        // res is 
        //{"result":"success","message":"User Deleted Successfully"}
        this.dialogRef.close({success:res.result == 'success',message:res.message })
      },
      (err) => {
        console.log(err);
        this.nbToaster.danger('Cannot Delete User'+ err, 'error', {duration:8_000});
        this.dialogRef.close()
      }
    );
  }

  cancel() {
    this.dialogRef.close();
  }
}
