<div class="" [nbSpinner]="isLoading">
  <div class="mb-3">
    <div class="card shadow-sm">
      <div class="card-head d-flex justify-content-between align-items-center py-3 px-3">
        <h6 class="p-0 m-0">Business Hours</h6>
        <button nbButton hero status="warning" size="small" (click)="onPostExceptionModalOpen()"><nb-icon icon="plus-outline"></nb-icon> Add Exception</button>
      </div>
      <div class="card-body py-3 bt-eee bb-eee">

        <div *ngFor="let day of localDays ;let i = index" class="day my-1">
          <div class="d-flex justify-content-between  align-items-center">
            <div class="d-flex align-items-center">
              <nb-toggle labelPosition="end"  [checked]="isDayOn(day)" (checkedChange)="onToggleClick($event,day)">{{day.dayName}}</nb-toggle>
              <span class="d-bloc ml-2 fw-bold"></span>
            </div>
            <button nbButton size="large" class="justify-self-end" status="success" ghost (click)="addShift(day)">
              <nb-icon icon="plus-circle-outline"></nb-icon>
            </button>

          </div>

          <div class="row mt-3" *ngFor="let shift of day.shifts; let i = index">
            <div class="d-flex justify-content-end align-items-center">
              <div class="">
                <input nbInput type="text" class="custom-time-picker" [nbTimepicker]="timepicker" [(ngModel)]="shift.from" />
                <nb-timepicker #timepicker="nbTimepicker" twelveHoursFormat></nb-timepicker>
              </div>
              
              <span class="px-1 text-nowrap">To :</span>
              
              <div class="">
                <input nbInput type="text" class="custom-time-picker" [nbTimepicker]="timepicker2" [(ngModel)]="shift.to"  />
                <nb-timepicker #timepicker2="nbTimepicker" twelveHoursFormat ></nb-timepicker>
              </div>
              <button nbButton status="warning" class="align-self-end" ghost (click)="deleteShift(day,i)">
                <nb-icon icon="trash-outline" ></nb-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
        <div class="py-3 px-3" >
          <button style="display: block;margin-left: auto;" nbButton status="success" [disabled]="(!isDirty && !isLoading)" (click)="onSave()">Save</button>
        </div>
    </div>
  </div>
</div>
