import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { catchError, EMPTY, Subscription } from 'rxjs';
import { validate } from 'uuid';
import { WorkingHoursService } from '../../../services/working-hours.service';
import { PostExceptionHoursRequest, TimeType } from '../types/working-hours';


type PostExceptionHoursForm ={
  fromDate: FormControl<Date>;
  toDate: FormControl<Date>;
  from: FormControl<Date>;
  to: FormControl<Date>;
  isAllDayClosed:FormControl<boolean>;
  reason:FormControl<string | undefined>;

};





@Component({
  selector: 'app-post-exception-hours-modal',
  templateUrl: './post-exception-hours-modal.component.html',
  styleUrl: './post-exception-hours-modal.component.scss'
})
export class PostExceptionHoursModalComponent implements OnInit,OnDestroy {
  @Input() branchId!:string;
  form!:FormGroup<PostExceptionHoursForm>
  subscriptions:Subscription[]=[]
  isSubmitting:boolean=false;

  constructor(
    private fb : FormBuilder,
    private toastr: NbToastrService,
    private workingHoursService: WorkingHoursService,
    private dialogRef: NbDialogRef<PostExceptionHoursModalComponent>)
  {}
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe())
  }
  ngOnInit(): void {
    if(!this.branchId)
      this.dialogRef.close(false)
    this.initForm()
  }

  initForm(){
    let now = new Date()
    now.setHours(0,0,0,0)
    this.form = this.fb.group<PostExceptionHoursForm>({
      fromDate: this.fb.nonNullable.control<Date>(new Date(now.getTime())),
      toDate: this.fb.nonNullable.control<Date>(new Date(now.getTime())),
      from: this.fb.nonNullable.control<Date>(new Date(now.getTime())),
      to: this.fb.nonNullable.control<Date>(new Date(now.getTime())),
      isAllDayClosed:this.fb.nonNullable.control<boolean>(false),
      reason:this.fb.nonNullable.control<string | undefined>('')
    })
  }

  convertDate2FormattedTime(date: Date): [string, string] {
    
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let type = TimeType.AM;

    // AM or PM
    if (hours >= 12) {
      type = TimeType.PM;
    }
    
    // if the hours > 12 => reminder is the hours value and PM already detected 
    // if the hours < 12 and 00(12am) reminder==0 => falsy value => 12 is set for the 12 am  
    hours = hours % 12 || 12; 

    const formattedHours = hours < 10 ? `0${hours}` : `${hours}`;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

    return [`${formattedHours}:${formattedMinutes}`, type];
  }

  onSubmit(){
    this.isSubmitting = true;
    console.log(this.form.value)

    let [to,toType] = this.convertDate2FormattedTime(this.form.controls.to.value);
    let [from,fromType] = this.convertDate2FormattedTime(this.form.controls.from.value);
    
    const request = {
      branchId:this.branchId,
      fromDate:this.form.controls.fromDate.value.toISOString(),
      toDate:this.form.controls.fromDate.value.toISOString(),
      from:from,
      fromType:fromType,
      to:to,
      toType:toType,
      isAllDayClosed:this.form.controls.isAllDayClosed.value,
      workType:1,
      reason:this.form.controls.reason.value

      
    } as PostExceptionHoursRequest

    // post
    this.subscriptions.push(
      this.workingHoursService.postExceptionHours(request)
      .pipe(
        catchError(err => {
          this.isSubmitting = false
          this.toastr.danger("Configuring Exception Hours Failed","Error")
          return EMPTY
        })
      )
      .subscribe(res => {
        this.isSubmitting = false
        this.dialogRef.close(true)
      })
    )

  }

  onCancel(){
    this.dialogRef.close();
  }

}
 