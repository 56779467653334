import { Component, input, Input, OnDestroy, OnInit } from '@angular/core';
import { CreditService } from '../credit.service';
import {
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { GetCreditSettingsRes, ManageCreditReq } from '../types/credit';
import { Subscription } from 'rxjs';
import { NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-credit-management',
  templateUrl: './credit-management.component.html',
  styleUrl: './credit-management.component.scss',
})
export class CreditManagementComponent implements OnInit, OnDestroy {
  @Input() branchId!: string;
  currentSettings!: GetCreditSettingsRes;
  manageCreditForm = this.fb.group({
    branchId: this.fb.control('', Validators.required),
    creditMaxAmount: this.fb.control(0, Validators.min(0)),
    creditTimeLimit: this.fb.control(0, Validators.min(0)),
  });
  subscriptions: Subscription[] = [];

  constructor(
    private creditService: CreditService,
    private fb: NonNullableFormBuilder,
    private nbToastr: NbToastrService,
  ) {}

  ngOnInit(): void {
    this.getBranchId();
    this.getCurrentSettings();
  }
  
  getCurrentSettings(){
    this.subscriptions.push(
      this.creditService.getCreditSettings(this.branchId).subscribe({
        next: (res) => {
          this.currentSettings = res;
          this.patchValues();
        },
        error: (res) => {
          console.error(res);
        },
      })
    )
  }

  patchValues() {
    this.manageCreditForm.patchValue({
      ...this.currentSettings,
    });
  }
  
  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  getBranchId() {
    if (!this.branchId) {
      if (JSON.parse(localStorage.getItem('searchVal')!).type == 'branch'){
        this.branchId = JSON.parse(localStorage.getItem('searchVal')!).id;
      }
      else console.error('failed to obtain branch id');
    }
  }

  onCancel(){
    if(this.manageCreditForm.dirty && this.currentSettings){
      this.manageCreditForm.patchValue({
        ... this.currentSettings
      })
    }
  }
  onSave() {
    if (this.manageCreditForm.valid) {
      this.subscriptions.push(
        this.creditService.updateCreditSettings(
          this.manageCreditForm.value as ManageCreditReq
        ).subscribe({
          next: res => {
            this.nbToastr.success('Settings updated successfully','Saved')
            this.manageCreditForm.reset();
            this.getCurrentSettings();
          },
          error: res => {
            console.error(res)
            this.nbToastr.danger('Something went wrong','Error');
            
          }
        })
      );
    }
    else {
      this.nbToastr.danger('Invalid Values','Error');
    }
  }
}
