import { Component, Input, OnInit } from '@angular/core';
import { NbDialogService } from '@nebular/theme';
import { AddAreaComponent } from './add-area/add-area.component';
import { AreasService } from '../../services/areas.service';
import { map } from 'rxjs';
import { BranchService } from '../../services/branch.service';
import { DeleteAreaComponent } from './delete-area/delete-area.component';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-delivery-management',
  templateUrl: './delivery-management.component.html',
  styleUrl: './delivery-management.component.scss'
})
export class DeliveryManagementComponent implements OnInit{
  @Input() branchID!: any; // branch id passed to component

  areas: any = [];
  loading: boolean = false;
  countryId:any;
  constructor(
    private dialogService: NbDialogService,
    private branchService: BranchService,
    private areasService : AreasService,
    public userservice: UserService,

  ) { }
  ngOnInit(): void {
    // alert(this.branchID);
    console.log(this.branchID);
    this.getBranchAreas();
    this.getbranchbyId();
    
  }
  getBranchAreas(){
    this.areasService.getBranchAreas(this.branchID)
    .subscribe({
      next: (res:any) => {
        this.areas = res;
      },
      error: err =>{
        console.log(err);
        
      }
    });
  }
  getbranchbyId(){
    this.branchService.getBusinessByBranchId(this.branchID).subscribe((res: any)=> {
      this.countryId = res.branch?.address?.countryId;
      console.log('countryId',this.countryId);
      
    })
  }

  onCreateArea(){
    const modalRef = this.dialogService.open(AddAreaComponent, {
      context: {
        branchID: this.branchID,
        countryId : this.countryId
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.getBranchAreas();

    });
  }
  onEdit(area:any){
    const modalRef = this.dialogService.open(AddAreaComponent,{
      context : {
        branchID: this.branchID,
        area : area
      }
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
     this.getBranchAreas();

    });
  }
  onDelete(id:any){
    const modalRef = this.dialogService.open(DeleteAreaComponent, {
      context: {
        branchAreaId: id,
        branchId : this.branchID
      },
    });
    modalRef.onClose.subscribe((res: string) => {
      console.log(res);
      this.getBranchAreas();
      this.userservice.logs.emit('delete branch Area in branch log');
    });
  }
}
