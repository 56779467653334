import { Component, Input } from '@angular/core';
import { GetRoleRes } from '../types/role';
import { RolesService } from '../roles.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';

@Component({
  selector: 'app-delete-role-modal',
  templateUrl: './delete-role-modal.component.html',
  styleUrl: './delete-role-modal.component.scss',
})
export class DeleteRoleModalComponent {
  @Input() role!: GetRoleRes;

  constructor(
    private rolesService: RolesService,
    private toastrService: NbToastrService,
    private dialogRef: NbDialogRef<DeleteRoleModalComponent>
  ) {}

  onConfirm() {
    this.rolesService.deleteRole(this.role.id).subscribe({
      next: (res) => {
        this.toastrService.success(`Role '${this.role.name}' Deleted Successfully`, 'Success');
        this.dialogRef.close(true);
      },
      error: (res) => {
        this.toastrService.danger(
          `Failed to delete role: '${this.role.name}'. error: ${res}`,
          'Error',
          {
            duration: 8_000,
          }
        );
        this.dialogRef.close(false);
      },
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
