import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbAuthService } from '@nebular/auth';
import { NbSidebarService, NbMenuService, NbMenuItem } from '@nebular/theme';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrl: './sales.component.scss',
})
export class SalesComponent implements OnInit, OnDestroy {
  onClose: Subject<boolean> = new Subject();

  state: string = '';
  items: NbMenuItem[] = [
    {
      title: 'Dashboard',
      link: '/sales',
      queryParams: {},
      icon: 'home-outline',
    },
  ];

  constructor( private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private authService: NbAuthService,
    private route: ActivatedRoute,
    private router: Router){}
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
  }

  onToggle() {
    this.sidebarService.toggle(false, 'sidebar');
  }
  onStateChange() {
    this.sidebarService.getSidebarState('sidebar').subscribe(res => {
      this.state = res
    });
  }
  closeMenu() {
    this.onClose.next(false);
  }
}
