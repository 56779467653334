<nb-layout>
  <nb-layout-header subheader>
      <button class="me-5" *ngIf="state == 'collapsed'" nbButton ghost (click)="onToggle()">
          <nb-icon icon="menu-2-outline" status="basic" [options]="{ animation: { type: 'pulse' } }"></nb-icon>
      </button>
      <div class="user d-flex justify-content-between align-items-center w-100">
          <div></div>
          <app-dropdown-menu [onClose]="onClose"></app-dropdown-menu>
      </div>
  </nb-layout-header>
  <nb-sidebar class="shadow-sm" responsive [compactedBreakpoints]="['']"
      [collapsedBreakpoints]="['xs', 'is', 'sm', 'md']" (stateChange)="onStateChange()" tag="sidebar">
      <nb-sidebar-header>
          <div class="logo d-flex align-items-center justify-content-between">
              <img src="assets/imgs/Logo.svg" class="logo-img img-fluid" alt="smartEpay">
              <button nbButton ghost (click)="onToggle()">
                  <nb-icon icon="arrowhead-left-outline" status="basic" [options]="{ animation: { type: 'pulse' } }">
                  </nb-icon>
              </button>
          </div>
      </nb-sidebar-header>
      <nb-menu class="mb-3" tag="itemsMenu" [items]="items"></nb-menu>
      <hr class="m-0">
  </nb-sidebar>
  <nb-layout-column class="p-2" (click)="closeMenu()">
      <router-outlet></router-outlet>
  </nb-layout-column>
</nb-layout>