import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  catchError,
  EMPTY,
  EmptyError,
  Observable,
  of,
  throwError,
} from 'rxjs';
import { EditBranchCreationRequest, GetBranchCreationRequestResponse, GetPendingBranchCreationCountResponse, PostBranchCreationRequest } from './types/branch-creation-request';
import {
  FilteredResponse,
  FilterInfo,
  FilteringOptions,
} from '../expenses/types/filtering-options';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BranchCreationRequestsService {
  constructor(private http: HttpClient) {}

  getBranchCreationRequests(filter: FilteringOptions): Observable<FilteredResponse<GetBranchCreationRequestResponse>> {
    // return of({
    //   "value": [
    //     {
    //       "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //       "branchName": "brach 1",
    //       "menuFileUrl": "ulr/segment/last/name.ts",
    //       "creationFileUrl": "sasdf",
    //       "images": [
    //         {
    //           "name": "BranchLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         },
    //         {
    //           "name": "DefaultLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         },
    //         {
    //           "name": "PrintingLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         },
    //         {
    //           "name": "FooterLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         }
    //       ],
    //       "salesManId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //       "salesManName": "string",
    //       "lastUpdatedById": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //       "lastUpdatedBy": "support 1",
    //       "status": "pending",
    //       "createdAt": "2024-12-08T08:18:35.369Z",
    //       "lastUpdated": "2025-01-09T08:18:35.369Z"
    //     },
    //     {
    //       "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //       "branchName": "brach 2",
    //       "menuFileUrl": "ulr/segment/last/name.ts",
    //       "creationFileUrl": "sasdf",
    //       "images": [
    //         {
    //           "name": "BranchLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         },
    //         {
    //           "name": "DefaultLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         },
    //         {
    //           "name": "PrintingLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         },
    //         {
    //           "name": "FooterLogoImage",
    //           "url": "ulr/segment/last/name.ts"
    //         }
    //       ],
    //       "salesManId": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //       "salesManName": "string",
    //       "lastUpdatedById": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
    //       "lastUpdatedBy": "support 1",
    //       "status": "pending",
    //       "createdAt": "2024-12-08T08:18:35.369Z",
    //       "lastUpdated": "2025-01-09T08:18:35.369Z"
    //     },
    //   ],
    //   "currentPageNumber": 1,
    //   "pageSize": 10,
    //   "totalPageCount": 2,
    //   "totalRecordsCount": 20
    // })

    return this.http.post<FilteredResponse<GetBranchCreationRequestResponse>>(
      environment.baseURL + '/BranchCreationRequests/filter',
      filter
    );
  }
  post(request:PostBranchCreationRequest):Observable<any>{
    let data = new FormData();
    data.append('branchName',request.branchName)
    data.append('branchName', request.branchName);

    if (request.creationFile) {
      data.append('creationFile', request.creationFile);
    }
    if (request.menuFile) {
      data.append('menuFile', request.menuFile);
    }
    if (request.branchLogo) {
      data.append('branchLogo', request.branchLogo);
    }
    if (request.defaultLogo) {
      data.append('defaultLogo', request.defaultLogo);
    }
    if (request.printingLogo) {
      data.append('printingLogo', request.printingLogo);
    }
    if (request.footerLogo) {
      data.append('footerLogo', request.footerLogo);
    }

    return this.http.post(environment.baseURL+'/BranchCreationRequests',data)
  }

  delete(id:string):Observable<any>{
    return this.http.delete(environment.baseURL+'/BranchCreationRequests/'+id)
  }
  edit(req:EditBranchCreationRequest){
    return this.http.put(environment.baseURL+'/BranchCreationRequests',req)
  }
  getPendingRequestsCount():Observable<GetPendingBranchCreationCountResponse>{
    return this.http.get<GetPendingBranchCreationCountResponse>(environment.baseURL+'/BranchCreationRequests/PendingCount')
  }
}
