import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NbDialogRef, NbDialogService, NbToastrService } from '@nebular/theme';

import { ResetPasswordUserBranchComponent } from '../reset-password-user/reset-password-user.component';
import { UserService } from '../../../services/user.service';
import { UsersService } from '../../users-and-roles/users.service';
import { GetUserRes } from '../../users-and-roles/types/user';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordBranchComponent implements OnInit {
  user!: GetUserRes;
  forgotUserForm: FormGroup = new FormGroup({
    email: new FormControl(''),
  });
  constructor(
    public userService: UserService,
    private dialogService: NbDialogService,
    private usersService: UsersService,
    protected dialogRef: NbDialogRef<ForgotPasswordBranchComponent>,
    private nbToaster: NbToastrService
  ) {}

  ngOnInit(): void {
    this.forgotUserForm.setValue({
      email: this.user.email,
    });
  }
  goTo() {
    this.usersService
      .sendForgetPasswordRequest(this.forgotUserForm.value)
      .subscribe({
        next: (res: any) => {
          // res is {"result":"success"}
          if (res.result == "success") {
            this.dialogRef.close(true)
          } else {
            this.dialogRef.close(false)
          }      
          this.dialogRef.close();
          this.dialogService.open(ResetPasswordUserBranchComponent, {
            context: {
             user:this.user
            },
          });
        },
        error: (err) => {
          console.error({ err });
          this.nbToaster.show(err.error.Message, 'error', {
            status: 'danger',
            hasIcon: false,
            icon: 'close-outline',
          });
        },
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
