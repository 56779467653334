<div class="row">
  <form [formGroup]="loyaltySettingsForm">
    <nb-toggle class="mb-3" formControlName="loyaltyAllowed" labelPosition="start" >Loyalty</nb-toggle>
    <div class="mb-3 col-12">
      <label class="mb2 col-12">Recharge Point Per Currency Rate</label>
      <input type="number" formControlName="rechargePointPerCurrency" nbInput fullWidth  >
      <small>if customer payed 10$, his loyalty points increase by <i>10 * {{loyaltySettingsForm.controls.rechargePointPerCurrency.value}} </i> which equals {{10 *loyaltySettingsForm.controls.rechargePointPerCurrency.value}} points</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.rechargePointPerCurrency.errors?.['required'] && loyaltySettingsForm.controls.rechargePointPerCurrency.dirty">required</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.rechargePointPerCurrency.errors?.['min'] && loyaltySettingsForm.controls.rechargePointPerCurrency.dirty">minimum value is 0</small>
    </div>
    <div class="mb-3 col-12">
      <label class="mb2 col-12">Redeem Point Per Currency Rate</label>
      <input type="number" formControlName="redeemPointPerCurrency" nbInput fullWidth  >
      <small>if customer want to pay 10$, he will need <i>10 / {{loyaltySettingsForm.controls.redeemPointPerCurrency.value}} loyalty points </i> which equals {{10 /loyaltySettingsForm.controls.redeemPointPerCurrency.value}} points</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.redeemPointPerCurrency.errors?.['required'] && loyaltySettingsForm.controls.redeemPointPerCurrency.dirty">required</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.redeemPointPerCurrency.errors?.['min'] && loyaltySettingsForm.controls.redeemPointPerCurrency.dirty">minimum value is 0</small>
    </div>
    <div class="mb-3 col-12">
      <label class="mb2 col-12">Notify Customer {{loyaltySettingsForm.controls.remainedDaysToExpirePointToNotifyCustomer.value}} Days Before Point Expiration</label>
      <input type="number" formControlName="remainedDaysToExpirePointToNotifyCustomer" nbInput fullWidth  >
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.remainedDaysToExpirePointToNotifyCustomer.errors?.['required'] && loyaltySettingsForm.controls.remainedDaysToExpirePointToNotifyCustomer.dirty">required</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.remainedDaysToExpirePointToNotifyCustomer.errors?.['min'] && loyaltySettingsForm.controls.remainedDaysToExpirePointToNotifyCustomer.dirty">minimum value is 1</small>
    </div>
    <div class="mb-3 col-12">
      <label class="mb2 col-12">Minimum Expiring Points Required to Notify Customer</label>
      <input type="number" formControlName="maxExpiringPointToNotifyCustomer" nbInput fullWidth  >
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.maxExpiringPointToNotifyCustomer.errors?.['required'] && loyaltySettingsForm.controls.maxExpiringPointToNotifyCustomer.dirty">required</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.maxExpiringPointToNotifyCustomer.errors?.['min'] && loyaltySettingsForm.controls.maxExpiringPointToNotifyCustomer.dirty">minimum value is 1</small>
    </div>
    <div class="mb-3 col-12">
      <label class="mb2 col-12">Points Expire After (Days)</label>
      <input type="number" formControlName="expirePointDurationByDay" nbInput fullWidth  >
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.expirePointDurationByDay.errors?.['required'] && loyaltySettingsForm.controls.expirePointDurationByDay.dirty">required</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.expirePointDurationByDay.errors?.['min'] && loyaltySettingsForm.controls.expirePointDurationByDay.dirty">minimum value is 1</small>
    </div>
    <nb-toggle class="mb-3" formControlName="isSplitPaymentByPointsAllowed" labelPosition="start" >Split Payment with loyalty points</nb-toggle>
    <div class="mb-3 col-12">
      <label class="mb2 col-12">Percentage limit for paying with points (%)</label>
      <input type="number" [disabled]="!loyaltySettingsForm.controls.isSplitPaymentByPointsAllowed.value" formControlName="maxPercentOfPayByPoint" nbInput fullWidth  >
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.maxPercentOfPayByPoint.errors?.['required'] && loyaltySettingsForm.controls.maxPercentOfPayByPoint.dirty">required</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.maxPercentOfPayByPoint.errors?.['min'] && loyaltySettingsForm.controls.maxPercentOfPayByPoint.dirty">minimum value is 1</small>
      <small class="text-danger d-block" *ngIf="loyaltySettingsForm.controls.maxPercentOfPayByPoint.errors?.['max'] && loyaltySettingsForm.controls.maxPercentOfPayByPoint.dirty">maximum value is 100</small>
    </div>
    <div class="mb-1 col-12 d-flex justify-content-end">
      <button nbButton status="success" (click)="onSave()" [disabled]="!loyaltySettingsForm.dirty || !loyaltySettingsForm.valid">Save<nb-icon icon="save-outline"></nb-icon></button>
    </div>
    <small class="text-danger d-block text-end" *ngIf="!loyaltySettingsForm.valid">there is one or more validation errors</small>
  </form> 
</div>
