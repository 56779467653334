import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { observableToBeFn } from 'rxjs/internal/testing/TestScheduler';
import { GetCreditSettingsRes, ManageCreditReq } from './types/credit';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreditService {

  constructor(private http: HttpClient) { }

  getCreditSettings(branchId:string):Observable<GetCreditSettingsRes>{
    return this.http.get<GetCreditSettingsRes>(`${environment.baseURL}/BranchSettings/GetCreditSettings?branchId=${branchId}`)
  }
  updateCreditSettings(req:ManageCreditReq){
    return this.http.post(`${environment.baseURL}/BranchSettings/ManageCreditSettings`,req)
  }
}
