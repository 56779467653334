<nb-card>
  <nb-card-header>
          <h5 class="modal-title">Forgot User Password</h5>
  </nb-card-header>
  <nb-card-body>
    <form [formGroup]="forgotUserForm">
      <div class="row form-row">
      <div class="col-12 mb-3">
        <label for="recipient-name" class="col-form-label">Email </label>
        <input type="email" fullWidth nbInput  [value]="user.email" class="input-style" disabled>
      </div>
    </div>
    </form>
  </nb-card-body>
  <nb-card-footer class="text-end">  
    <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
    <button nbButton  status="success" (click)="goTo()"><nb-icon icon="save-outline"></nb-icon>Next</button>
  </nb-card-footer>
</nb-card>