import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SuggestService {

  endpoint: string = '/Suggest';
  url: string = environment.baseURL + this.endpoint;

  constructor(private http: HttpClient) { }

  getCategoriesWithImages(businessTypeId:any){
    return this.http.get(this.url + `/GetCategoriesWithImages?businessTypeId=${businessTypeId}`);
  }
  getSubCategoriesWithImages(businessTypeId:any){
    return this.http.get(this.url + `/GetSubCategoriesWithImages?businessTypeId=${businessTypeId}`);
    
  }
  getItemsWithImages(businessTypeId:any){
    return this.http.get(this.url + `/GetItemsWithImages?businessTypeId=${businessTypeId}`);
  }

  addCategoryImage(body:any){
    return this.http.post(this.url + '/AddCategoryImage',body,{ responseType: 'text' });
  }
  addSubCategoryImage(body:any){
    return this.http.post(this.url + '/AddSubCategoryImage',body,{ responseType: 'text' });
  }
  addItemImage(body:any){
    return this.http.post(this.url + '/AddItemImage',body,{ responseType: 'text' });
  }
}
