import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { LoyaltyService } from '../loyalty.service';
import { Subscription } from 'rxjs';
import { GetLoyaltySettingsRes, PutLoyaltySettingsReq } from '../types/loyalty';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { NbToastrService } from '@nebular/theme';

type PutLoyaltySettingsForm = FormGroup<{
  MaxExpiringPointToNotifyCustomer: FormControl<number>;
  RemainedDaysToExpirePointToNotifyCustomer: FormControl<number>;
  branchId: FormControl<string>;
  expirePointDurationByDay: FormControl<number>;
  isSplitPaymentByPointsAllowed: FormControl<boolean>;
  loyaltyAllowed: FormControl<boolean>;
  maxPercentOfPayByPoint: FormControl<number>;
  rechargePointPerCurrency: FormControl<number>;
  redeemPointPerCurrency: FormControl<number>;
}>;
@Component({
  selector: 'app-loyalty-management',
  templateUrl: './loyalty-management.component.html',
  styleUrl: './loyalty-management.component.scss',
})
export class LoyaltyManagementComponent implements OnInit, OnDestroy {
  @Input() branchId!: string;
  loyaltySettings!: GetLoyaltySettingsRes;
  subscriptions: Subscription[] = [];
  loyaltySettingsForm = this.fb.group({
    maxExpiringPointToNotifyCustomer: this.fb.control<number>(0, [
      Validators.min(1),
      Validators.required,
    ]),
    remainedDaysToExpirePointToNotifyCustomer: this.fb.control<number>(0, [
      Validators.min(1),
      Validators.required,
    ]),
    branchId: this.fb.control<string>('', [Validators.required]),
    expirePointDurationByDay: this.fb.control<number>(0, [
      Validators.min(1),
      Validators.required,
    ]),
    isSplitPaymentByPointsAllowed: this.fb.control<boolean>(false, [
      Validators.required,
    ]),
    loyaltyAllowed: this.fb.control<boolean>(false, [Validators.required]),
    maxPercentOfPayByPoint: this.fb.control<number>(0, [
      Validators.min(1),
      Validators.max(100),
      Validators.required,
    ]),
    rechargePointPerCurrency: this.fb.control<number>(0, [
      Validators.min(0),
      Validators.required,
    ]),
    redeemPointPerCurrency: this.fb.control<number>(0, [
      Validators.min(0),
      Validators.required,
    ]),
  });
  constructor(
    private loyaltyService: LoyaltyService,
    private fb: NonNullableFormBuilder,
    private nbToastr: NbToastrService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => subs.unsubscribe());
  }

  ngOnInit(): void {
    if (this.branchId) {
      this.subscriptions.push(
        this.loyaltyService.getLoyaltySettings(this.branchId).subscribe({
          next: (res) => {
            this.loyaltySettings = res;
            this.patchValues();
          },
          error: (err) => this.nbToastr.danger(err, 'error'),
        })
      );
    }
  }

  patchValues() {
    this.loyaltySettingsForm.patchValue({
      branchId: this.branchId,
      maxExpiringPointToNotifyCustomer:
        this.loyaltySettings.branchMaxExpiringPointToNotifyCustomer,
      remainedDaysToExpirePointToNotifyCustomer:
        this.loyaltySettings.branchRemainedDaysToExpirePointToNotifyCustomer,
      expirePointDurationByDay:
        this.loyaltySettings.branchExpirePointDurationByDay,
      isSplitPaymentByPointsAllowed:
        this.loyaltySettings.branchIsSplitPaymentByPointsAllowed,
      loyaltyAllowed: this.loyaltySettings.loyaltyAllowed,
      maxPercentOfPayByPoint: this.loyaltySettings.branchMaxPercentOfPayByPoint,
      rechargePointPerCurrency:
        this.loyaltySettings.branchRechargePointPerCurrency,
      redeemPointPerCurrency: this.loyaltySettings.branchRedeemPointPerCurrency,
    });
  }

  onSave() {
    this.subscriptions.push(
      this.loyaltyService
        .saveLoyaltySettings(
          this.loyaltySettingsForm.value as PutLoyaltySettingsReq
        )
        .subscribe({
          next: (res) =>
            this.nbToastr.success(
              'Loyalty Settings Changes Saved Successfully',
              'Saved'
            ),
          error: (error) => this.nbToastr.danger(`Error: ${error}`, 'Error'),
        })
    );
  }
}
