import { Component } from '@angular/core';
import { NbDialogRef } from '@nebular/theme';

@Component({
  selector: 'app-delete-branch-creation-request-dialog',
  templateUrl: './delete-branch-creation-request-dialog.component.html',
  styleUrl: './delete-branch-creation-request-dialog.component.scss'
})
export class DeleteBranchCreationRequestDialogComponent {
  constructor(private dialogRef:NbDialogRef<DeleteBranchCreationRequestDialogComponent>){}

  onConfirm(){
    this.dialogRef.close(true)
  }
  onCancel(){
    this.dialogRef.close(false)
  }
}
