<div class="mt-1">
  <div class="text-end">
    <div class="col-12">
      <button status="success" nbButton hero class="btn" (click)="addRole()">
        <nb-icon icon="plus-square-outline"></nb-icon>
        Add Role
      </button>
    </div>
</div>
<div class="padding"></div>
<strong *ngIf="!roles.length" style="text-align: center">No Roles Found!</strong>
<div class="table-responsive" *ngIf="roles.length">
  <table class="table table-bordered">
    <thead>
      <tr>
        <th scope="col-3">No</th>
        <th scope="col-3">Name</th>
        <th scope="col-3">Description</th>
        <!-- <th scope="col-1">Is Default</th> -->
        <th scope="col-2">Actions</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let role of roles; let i = index">
        <th scope="row">{{ i + 1 }}</th>
        <th scope="row">{{ role.name }}</th>
        <td>{{ role.description }}</td>
        <!-- <td> NOTE : DELETE IF Is Default Col DELETED
          <div class="text-center">
            <nb-icon *ngIf="role.isDefault" icon="checkmark-outline"></nb-icon>
            <nb-icon *ngIf="!role.isDefault" icon="close-outline"></nb-icon> 
          </div>
        </td>-->
        <td> 
          <div class="row">
            <div  class="col d-flex">
              <button  nbButton hero placement="left" ngbTooltip="Edit Role" tooltipClass="custom-tooltip"
                (click)="editRole(role)" shape="round" size="small" status="primary"
                [disabled]="role.isDefault">
                <nb-icon icon="edit-outline"></nb-icon>
              </button>
              <button nbButton placement="left" ngbTooltip="Delete Role" tooltipClass="custom-tooltip"
                (click)="deleteRole(role)" shape="round" size="small" status="warning"
                [disabled]="role.isDefault">
                <nb-icon icon="trash-outline"></nb-icon>
              </button>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
</div>