import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { EditRoleReq, GetClientAppPageRes, GetRoleRes } from '../types/role';
import { RolesService } from '../roles.service';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import {
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { Subscription } from 'rxjs';

type EditRoleForm = FormGroup<{
  roleId: FormControl<string>;
  name: FormControl<string>;
  description: FormControl<string | null>;
  accessiblePages: FormControl<number[]>;
}>;

@Component({
  selector: 'app-edit-role-modal',
  templateUrl: './edit-role-modal.component.html',
  styleUrl: './edit-role-modal.component.scss',
})
export class EditRoleModalComponent implements OnInit, OnDestroy {
  @Input() role!: GetRoleRes;

  allPages: GetClientAppPageRes[] = [];
  subscriptions: Subscription[] = [];
  editRoleForm!: EditRoleForm;

  constructor(
    private rolesService: RolesService,
    private dialogRef: NbDialogRef<EditRoleModalComponent>,
    private nbToaster: NbToastrService,
    private fb: NonNullableFormBuilder
  ) {}

  ngOnInit(): void {
    this.editRoleForm = this.fb.group({
      roleId: this.fb.control<string>('', Validators.required),
      description: this.fb.control<string | null>(''),
      accessiblePages: this.fb.control<number[]>([], Validators.required),
      name: this.fb.control<string>('', Validators.required),
    });

    this.editRoleForm.patchValue({
      roleId: this.role.id,
      name: this.role.name,
      description: this.role.description,
    });
    this.getAccessiblePages();
    this.getAllClientAppPages();
  }

  getAccessiblePages() {
    this.subscriptions.push(
      this.rolesService.getRoleAccessiblePages(this.role.id).subscribe({
        next: (res) => {
          this.editRoleForm.patchValue({
            accessiblePages: res.map((page) => page.id),
          });
        },
        error: (err) => this.nbToaster.danger(err, 'error'),
      })
    );
  }

  getAllClientAppPages() {
    this.subscriptions.push(
      this.rolesService.getClientApplicationPages().subscribe({
        next: (res) => {
          this.allPages = res;
        },
        error: (err) => {
          this.nbToaster.danger(err, 'error');
        },
      })
    );
  }

  onSave() {
    if (this.editRoleForm.invalid) {
      this.nbToaster.danger('Invalid Values', 'Error');
      return;
    }

    this.subscriptions.push(
      this.rolesService
        .editRole(this.editRoleForm.value as EditRoleReq)
        .subscribe({
          next: (res) => {
            this.dialogRef.close(true)
            this.nbToaster.success(`Role '${this.role.name}' Updated Successfully`,'Updated')
          },
          error: (err) => {
            this.nbToaster.danger(err, 'error');
          },
        })
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => {
      subs.unsubscribe();
    });
  }
}
