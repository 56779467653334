import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { AddUserBranchComponent } from '../../user-branch/add-user.component';
import { GetUserRes } from '../types/user';
import { UsersService } from '../users.service';
import { Subscription } from 'rxjs';
import { ForgotPasswordBranchComponent } from '../../user-branch/forgot-password/forgot-password.component';
import { EditUserBranchComponent } from '../../user-branch/edit-user/edit-user.component';
import { DeleteUserBranchComponent } from '../../user-branch/delete-user/delete-user.component';
import { BranchService } from '../../../services/branch.service';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrl: './users-list.component.scss',
})
export class UsersListComponent implements OnInit,OnDestroy {
  users: GetUserRes[] | null = null;
  subscriptions: Subscription[] = [];
  businessId?: string;
  @Input()
  branchId: any;
  constructor(
    private dialogService: NbDialogService,
    private branchService: BranchService,
    private usersService: UsersService,
    private nbToastr: NbToastrService
  ) {}
  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe())
  }

  ngOnInit() {
    if (this.branchId != null) {
      this.getUsers();
      this.getBusinessId()
    }
  }

  getUsers() {
    this.subscriptions.push(
      this.usersService
        .getBranchUsers(this.branchId)
        .subscribe((res) => (this.users = res))
    );
  }

  getBusinessId(){
    this.branchService
      .getBusinessByBranchId(this.branchId)
      .subscribe((res: any) => {
        this.businessId = res.branch.businessId;
      });
  }

  addUser() {
    if(!this.businessId){
      this.nbToastr.danger('Business id is not obtained yet... please try again. report this if this appears repeatedly.','Error',{
        duration:4000
      })
      return;
    }

    const modalRef = this.dialogService.open(AddUserBranchComponent, {
      context: {
        businessId: this.businessId,
        branchId: this.branchId,
      },
      dialogClass: 'model-full',
      hasScroll: true,
    });
    modalRef.onClose.subscribe((res?: boolean) => {
      if (res && res == true) {
        this.getUsers();
      }
    });
  }

  resetUserPassword(user:GetUserRes) {
    const modalRef = this.dialogService.open(ForgotPasswordBranchComponent, {
      context: {
        user:user
      },
    });
    this.subscriptions.push(
      modalRef.onClose.subscribe(
        (res:boolean) => {
          if(res == true){
            this.nbToastr.show("We Sended Code To User's Email", 'success', {
              status: 'success',
              hasIcon: false,
              icon: 'checkmark-outline',
              duration: 10_000,
            });
          }
          else if(res == false){
            this.nbToastr.danger("Something went wrong", 'error', 
            {
              duration: 10_000,
            });
          }
          
        }
      )
    )
  }

  editUser(user:GetUserRes) {
    if(!this.businessId){
      this.nbToastr.danger('Business id is not obtained yet... please try again. report this if this appears repeatedly.','Error',{
        duration:4000
      })
      return;
    }

    const modalRef = this.dialogService.open(EditUserBranchComponent, {
      context: {
        user:user,
        businessId:this.businessId
      },
    });
    modalRef.onClose.subscribe((res?: boolean) => {
      if (res && res == true) {
        this.getUsers();
      }
    });
  }

  deleteUser(id: string) {
    const modalRef = this.dialogService.open(DeleteUserBranchComponent, {
      context: {
        userId: id,
      },
    });
    modalRef.onClose.subscribe((res?: {success:boolean,message:string}) => {
      if(res && res?.success){
        this.nbToastr.success('User Deleted Successfully','Success',{duration:4_000})
        this.getUsers()
      }
      else if(res && !res.success){
        this.nbToastr.danger(`Failed To Deleted User, ${res.message}`,'Error',{duration:4_000})
      }
    });
  }
  userCanBeEdited(user:GetUserRes):boolean{
    const restrictedRoles = ['business']
    return !restrictedRoles.includes(user.role)
  }
}
