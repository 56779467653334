
    <!--delete-->
    <nb-card>
        <nb-card-header>
                <h5 class="modal-title">Edit Branch </h5>
              </nb-card-header>
                <nb-card-body>
                  Confirm Edit Branch ?
                </nb-card-body>
                <nb-card-footer class="text-end">  
                  <button nbButton  status="success" (click)="editBranch()"><nb-icon icon="save-outline"></nb-icon>Edit</button>
                <button nbButton  status="danger" (click)="cancel()"><nb-icon icon="close-outline"></nb-icon>Cancel</button>
                </nb-card-footer>
              </nb-card>
        <!--delete-->