import { Component, Input, OnDestroy, OnInit, viewChild, ViewChild } from '@angular/core';
import { GetClientAppPageRes, PostRoleReq } from '../types/role';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NonNullableFormBuilder,
  Validators,
} from '@angular/forms';
import { RolesService } from '../roles.service';
import { Subscription } from 'rxjs';
import { NbDialogRef, NbSelectComponent, NbToastrService } from '@nebular/theme';

type PostRoleForm = FormGroup<{
  name: FormControl<string>;
  description: FormControl<string | null>;
  businessId: FormControl<string>;
  accessiblePages: FormControl<number[]>;
}>;

@Component({
  selector: 'app-add-role-modal',
  templateUrl: './add-role-modal.component.html',
  styleUrl: './add-role-modal.component.scss',
})
export class AddRoleModalComponent implements OnInit, OnDestroy {
  @Input() businessId!: string;

  postRoleForm!: PostRoleForm;
  allPages: GetClientAppPageRes[] = [];
  submitted = false;

  subscription: Subscription[] = [];
  constructor(
    private fb: NonNullableFormBuilder,
    private nullableFb: FormBuilder,
    private rolesService: RolesService,
    private dialogRef: NbDialogRef<AddRoleModalComponent>,
    private nbToaster: NbToastrService
  ) {}

  ngOnDestroy(): void {
    this.subscription.forEach((subs) => subs.unsubscribe());
  }

  ngOnInit(): void {
    this.postRoleForm = new FormGroup({
      name: this.fb.control<string>('', Validators.required),
      businessId: this.fb.control<string>('', Validators.required),
      description: this.nullableFb.control<string>(''),
      accessiblePages: this.fb.control<number[]>([], Validators.required),
    });

    this.postRoleForm.patchValue({
      businessId: this.businessId,
    });
    this.getApplicationPages();
  }

  getApplicationPages() {
    this.subscription.push(
      this.rolesService.getClientApplicationPages().subscribe((res) => {
        this.allPages = res;
      })
    );
  }
  
  onSubmit() {
    if (this.postRoleForm.invalid) {
      this.submitted = true;
      this.nbToaster.danger('Validation Error', 'Error', { duration: 4_000 });
      return;
    }

    this.subscription.push(
      this.rolesService
        .postRole(this.postRoleForm.value as PostRoleReq)
        .subscribe({
          next: (res) => {
            // res is = GetRoleRes
            // {
            //   "id": "a3c18c84-d439-4b73-7bfc-08dd1b48e549",
            //   "name": "fordelete1",
            //   "description": "delete this role",
            //   "isDefault": false
            // }
            this.nbToaster.success('Role Successfully Added', 'Success');
            this.dialogRef.close(true);
          },
          error: (err) => {
            // error is centralized
            console.error(
              'unhandled error message at RolesService.postRole error response.',
              err
            );
            this.nbToaster.danger(err, 'Error', { duration: 8_000 });
          },
        })
    );
  }

  onCancel() {
    this.dialogRef.close();
  }
}
