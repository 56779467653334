import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { BuisnessService } from '../../services/buisness.service';


@Component({
  selector: 'app-edit-branch',
  templateUrl: './edit-branch.component.html',
  styleUrls: ['./edit-branch.component.scss']
})
export class EditBranchComponent implements OnInit {
  editBranchData:any
  extraParms:any
  param: any = {};
  @Input() branchId: any;
  loading: boolean = false;
  constructor(
    public businessService: BuisnessService,
    private nbToaster: NbToastrService,
    protected dialogRef: NbDialogRef<EditBranchComponent>, 
    ) { 
      let val = localStorage.getItem('searchVal');
      this.param = JSON.parse(val!);
      this.branchId = this.param.id;
    }

  ngOnInit(): void {
    console.log(this.extraParms,"..")

  }
  editBranch() {
    if(this.extraParms != null ) {
      this.editBranchData.extraParams =this.extraParms
    }
    this.editBranchData.branchParam.payment = (this.editBranchData.branchParam.payment).toString()
    console.log(this.editBranchData)

    this.loading = true;
    this.businessService
    
      .editBranchParams(this.editBranchData, this.branchId)
      .subscribe(
        (res: any) => {      
          if(res.isSucceeded == true) {
            this.onClose('success');
          } else {
            this.onClose(res.message);
          }
          this.nbToaster.show('Branch Edited successfully.', 'success', {
            status: 'success',
            hasIcon: false,
            icon: 'checkmark-outline',
          });
          this.businessService.refresh.emit();
          this.loading = false;
          console.log(this.editBranchData);
          this.dialogRef.close()
        },
        (errors) => {
          if (errors.error.StatusCode == 500) {
            this.nbToaster.show(errors.error.Message, 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          }
          else {
            this.nbToaster.show(errors.error?.errors[0], 'error', {
              status: 'danger',
              hasIcon: false,
              icon: 'close-outline',
            });
          }
          this.businessService.refresh.emit();
          this.dialogRef.close()
        }
      );
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel(){
    this.dialogRef.close()
  }
}
