import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { ConfirmResetPasswordReq, EditUserReq, ForgetPasswordReq, GetUserRes, PostUserReq, PostUserRes } from './types/user';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http :HttpClient) { }

  getBranchUsers(branchId: string):Observable<GetUserRes[]> {
    return this.http
      .get<GetUserRes[]>(`${environment.baseURL}/Users/branch?id=${branchId}`)
      .pipe(
        catchError(this.handelError)
      );
  }

  getBusinessUsers(businessId: string):Observable<GetUserRes[]> {
    return this.http
      .get<GetUserRes[]>(`${environment.baseURL}/Users/business?id=${businessId}`)
      .pipe(
        catchError(this.handelError)
      );
  }

  createUser(request:PostUserReq):Observable<PostUserRes>{
    return this.http.post<PostUserRes>(`${environment.baseURL}/users/create`,request)
    .pipe(catchError(this.handelErrorsWithValidations))
  }

  sendForgetPasswordRequest(request:ForgetPasswordReq){
    return this.http.post(`${environment.baseURL}/users/forgot-password`,request)
    .pipe(
      catchError(this.handelError)
    )
  }

  confirmResetPassword(request:ConfirmResetPasswordReq){
    return this.http.post(`${environment.baseURL}/users/reset-password`,request)
    .pipe(
      catchError(this.handelError)
    )
  }

  editUser(request:EditUserReq):Observable<any>{
    return this.http.patch(`${environment.baseURL}/users/edit`,request)
    .pipe(
      catchError(this.handelErrorsWithValidations)
    )
  }

  deleteUser(userId:string){
    return this.http.post(`${environment.baseURL}/users/${userId}/delete`,{})
    .pipe(
      catchError(this.handelError)
    )
  }
  // getUserPermission():Observable<>{

  // }
  handelError(httpError: HttpErrorResponse) {
    let errMsg = '';

    if (httpError.error instanceof ErrorEvent) {
      // client error (network error ...)
      errMsg = `Error: ${httpError.error.message}`;
    } else {
      // server error
      errMsg = `Server Error: ${httpError.message}`;
    }
    // this error will be received by the observers , as a observable error
    return throwError(() => errMsg);
  }
  handelErrorsWithValidations(httpError:HttpErrorResponse){
    let errMsg = '';
  
    if (httpError.error instanceof ErrorEvent) {
      // client-side or network error
      errMsg = `Client Error: ${httpError.error.message}`;
    } else {
      // Server-side error
      if (httpError.status === 400 && httpError.error?.errors) {
        // default .net validation errors
        const validationErrors = httpError.error.errors; // Key-Value pair
        const formattedErrors = Object.entries(validationErrors)
          .map(([key, value]) => {
            const messages = Array.isArray(value) ? value.join(', ') : value;
            return `${key}: ${messages}`;
          })
          .join('\n');
        errMsg = `Validation Errors:\n${formattedErrors}`;
      } else if (httpError.status === 500) {
        // general server errors
        errMsg = `Server Error (500): ${httpError.message || 'An unexpected error occurred.'}`;
      } else {
        // other server errors
        errMsg = `Error ${httpError.status}: ${httpError.message || 'Unknown error occurred.'}`;
      }
    }

    // Return an observable with the error message
    return throwError(() => new Error(errMsg));
  }
}
