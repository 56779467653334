import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService, NbToastrService } from '@nebular/theme';
import { Subscription, catchError, EMPTY, switchMap, of, tap, EmptyError, firstValueFrom } from 'rxjs';
import {
  FilteringOptions,
  OrderType,
  Logical,
  Operator,
} from '../../expenses/types/filtering-options';
import { BranchCreationRequestsService } from '../branch-creation-request.service';
import { EditBranchCreationRequest, GetBranchCreationRequestResponse, RequestStatus } from '../types/branch-creation-request';
import { ExpensesService } from '../../../services/expenses.service';
import { AddBranchCreationRequestModalComponent } from '../add-branch-creation-request-modal/add-branch-creation-request-modal.component';
import { DeleteBranchCreationRequestDialogComponent } from '../delete-branch-creation-request-dialog/delete-branch-creation-request-dialog.component';
import { EmailValidator } from '@angular/forms';
import { NbAuthService } from '@nebular/auth';

type DateRange = {
  start: Date;
  end: Date;
};

@Component({
  selector: 'app-branch-creation-request-table',
  templateUrl: './branch-creation-request-table.component.html',
  styleUrl: './branch-creation-request-table.component.scss',
})
export class BranchCreationRequestTableComponent implements OnInit, OnDestroy {
  data: GetBranchCreationRequestResponse[] = [];
  statuses = RequestStatus
  totalAmount: number = 0;
  filterOptions: FilteringOptions = this.getDefaultFilter();
  isFilterOn: boolean = false;
  filterDateRange?: DateRange;
  filterKeyword = '';
  filterByStatus?:string;
  currentPage: number = 1;
  totalPagesCount: number = -1;

  role!:string;
  userId!:string;

  isDirty=false;
  dirtyData: {req:GetBranchCreationRequestResponse,status:string}[] = [];
  filteredData!: GetBranchCreationRequestResponse[];
  subscriptions: Subscription[] = [];

  constructor(
    private branchCreationService: BranchCreationRequestsService,
    private expensesService: ExpensesService,
    private nbToastr: NbToastrService,
    private dialogService: NbDialogService,
    private authService: NbAuthService
  ) {}

  ngOnDestroy(): void {
    this.subscriptions.forEach(subs => subs.unsubscribe)
  }

  ngOnInit(): void {
    this.subscriptions.push(
      this.authService.onTokenChange().subscribe(res => {
        this.role = res.getPayload().role;
        this.userId = res.getPayload().userId;
        this.loadData();
      })
    )
  }

  toggleFilter() {
    this.isFilterOn = !this.isFilterOn;
    if (!this.isFilterOn) {
      this.filterOptions = this.getDefaultFilter();
      this.loadData();
    }
  }

  resetStatusFilter(){
    this.filterByStatus = undefined;
    this.filteredData = this.data
  }
  
  getDefaultFilter(): FilteringOptions {
    this.filterByStatus = undefined;
    this.filterDateRange = undefined;
    this.filterKeyword = ''
    return {
      count: 10,
      // the filter should be applied on the sales man id
      filterInfos: [],
      orderInfos: [
        {
          orderType: OrderType.DESC,
          property: 'createdAt',
        },
      ],
      page: 1,
    };
  }

  filter() {
    this.filterOptions = {
      count: 10,
      filterInfos: [],
      orderInfos: [
        {
          property: 'createdAt',
          orderType: OrderType.DESC,
        },
      ],
      page: 1,
    };
    if (this.filterKeyword.trim() != '') {
      this.filterOptions.filterInfos.push({
        logical: Logical.AND,
        operator: Operator.Contains,
        value: this.filterKeyword.trim(),
        propertyName: 'branchName',
      });
    }
    if (this.filterDateRange) {
      this.filterOptions.filterInfos.push(
        {
          logical: Logical.AND,
          operator: Operator.LessThan,
          propertyName: 'createdAt',
          value: `${this.filterDateRange.end.toDateString()}`,
        },
        {
          logical: Logical.AND,
          operator: Operator.GreaterThan,
          propertyName: 'createdAt',
          value: `${this.filterDateRange.start.toDateString()}`,
        }
      );
    }
    this.loadData();
  }

  onStatusFilterSelectChange(event:string){
    this.filteredData = this.data.filter(req => req.status == event)
  }

  loadData(resetPageSize=false) {
    if(this.role == 'sales'){
      this.filterOptions.filterInfos.push({
        propertyName:'salesManId',
        logical:Logical.AND,
        operator:Operator.Equals,
        value:this.userId,
      })
    }
    resetPageSize == true ? this.filterOptions.count = 10 : ''
    this.subscriptions.push(this.getDataFetchingSubscription());
  }

  loadNextPage() {
    if (this.filterOptions.page == this.totalPagesCount) {
      // set indicator to disable next btn
      return;
    }
    this.filterOptions.page += 1;
    this.subscriptions.push(this.getDataFetchingSubscription());
  }
  
  loadPreviousPage() {
    if (this.filterOptions.page == 1) {
      // set indicator to disable prev btn
      return;
    }
    this.filterOptions.page -= 1;
    // incase we perviously loaded the last page and it contained only few items
    this.filterOptions.count < 10 ? (this.filterOptions.count = 10) : '';

    this.subscriptions.push(this.getDataFetchingSubscription());
  }

  addNewRequest(){
    this.subscriptions.push(
    this.dialogService.open(AddBranchCreationRequestModalComponent)
    .onClose.subscribe(res => {
      if(res == true){
        this.loadData(true);
      }
    }))
  }

  download(url: string,name: string) {
    if (url) this.expensesService.downloadFile(url,name);
  }

  onDelete(req:GetBranchCreationRequestResponse){
    this.subscriptions.push(
      this.dialogService.open(DeleteBranchCreationRequestDialogComponent)
      .onClose.pipe(
        tap(res => console.log(res,(res && res == true))),
        switchMap(res => {
          if(res && res == true)
            return this.branchCreationService.delete(req.id)
          else 
            return EMPTY
        })
      ).
      subscribe(
        {
          next: res => {
            this.nbToastr.success('Deleted Successfully','Success');
            this.loadData();
          },
          error: res =>{
            this.nbToastr.danger('Failed To Delete','Error')
          }
        }
      )
    )
  }

  onSelectChange(event:string,req:GetBranchCreationRequestResponse){
    let indexOfReq = this.dirtyData.findIndex(d => d.req.id == req.id)
    let wasDirty = indexOfReq !=-1
    if(req.status != event ){
      this.isDirty=true

      if(wasDirty){
        this.dirtyData.at(indexOfReq)!.status = event 
      }
      else{
        this.dirtyData.push({req:req,status:event})
      }
      return
    }
    if(req.status == event && wasDirty){
      this.dirtyData.splice(indexOfReq,1);
      if(this.dirtyData.length == 0){
        this.isDirty = false
      }
    }

  }

 async onSaveChanges(){
  if (!this.isDirty) return;
    let totalDirty = this.dirtyData.length
    let edited = 0
    try {
      this.isDirty = false;

      for (const dirtyReq of this.dirtyData) {
        const status = this.statuses.find(
          (x) => x.name.toLocaleLowerCase() === dirtyReq.status
        )?.enumValue;
        //converts observable stream to promise and resolves only on first emmetion
        await firstValueFrom(
          this.branchCreationService.edit({
            id: dirtyReq.req.id,
            status: status,
          } as EditBranchCreationRequest)
        ).then(success => {
          edited +=1;
        });
      }
    } catch (error) {
      console.error("Error saving changes:", error);
    }
    if(edited == totalDirty){
      this.nbToastr.success(`${edited} Records successfully updated of total ${totalDirty} changed`,"Success",{
        duration:10_000
      })
    }
    else{
      this.nbToastr.danger(`${edited} Records successfully updated of total: ${totalDirty} changed`,"Error",{
        duration:10_000
      })
    }
    this.dirtyData = [];
    this.loadData();
  }

  getDataFetchingSubscription():Subscription{
    return this.branchCreationService
        .getBranchCreationRequests(this.filterOptions)
        .pipe(
          catchError((errMsg) => {
            this.nbToastr.danger('Failed to load data','Error')
            console.error(errMsg)
            return EMPTY;
          })
        )
        .subscribe((res) => {
          this.filterOptions.count = res.pageSize;
          this.filterOptions.page = res.currentPageNumber;
          this.totalPagesCount = res.totalPageCount;
          this.data = res.value;
          if(this.filterByStatus != undefined){
            this.filteredData = res.value.filter(req => req.status == this.filterByStatus)
          }
          else{
            this.filteredData = res.value;
          }
        })
  }

  dateRangeFilter = (date:Date) => date.getTime() < new Date().getTime();
}
