import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NbDialogRef, NbToastrService } from '@nebular/theme';
import { User } from '../../../models/user.model';
import { UserService } from '../../../services/user.service';
import { GetBranchRes } from '../../users-and-roles/types/branch';
import { GetUserRes } from '../../users-and-roles/types/user';
import { RolesService } from '../../users-and-roles/roles.service';
import { GetRoleRes } from '../../users-and-roles/types/role';
import { UsersService } from '../../users-and-roles/users.service';
import { BuisnessService } from '../../../services/buisness.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.scss'],
})

export class EditUserBranchComponent implements OnInit {
  user!: GetUserRes;
  businessId!: string;
  // not passed from the list component
  roles: GetRoleRes[] = [];
  businessBranches: GetBranchRes[] = [];
  roleId!: string;
  submitted = false;

  editUserForm: FormGroup = new FormGroup({
    id: new FormControl('', Validators.required),
    username: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required,Validators.email]),
    phoneNumber: new FormControl('', [Validators.required,Validators.pattern(/^\+?\d{10,15}$/)]),
    accessibleBranches: new FormControl([], Validators.required),
    roleId: new FormControl('', Validators.required),
  });

  constructor(
    private usersService: UsersService,
    public userService: UserService,
    private businessService: BuisnessService,
    private rolesService: RolesService,
    protected dialogRef: NbDialogRef<EditUserBranchComponent>,
    private nbToaster: NbToastrService
  ) {}

  ngOnInit(): void {
    this.editUserForm.patchValue({
      id: this.user.id,
      email: this.user.email,
      username: this.user.userName,
      phoneNumber: this.user.phoneNumber,
    });
    this.getBusinessBranches();
    this.getBusinessRoles();
  }

  getBusinessBranches() {
    this.businessService
      .getBuisnessBranches(this.businessId)
      .subscribe(
        (res: any) => (this.businessBranches = res.branches as GetBranchRes[])
      );
  }

  getBusinessRoles() {
    this.rolesService.getBusinessRoles(this.businessId).subscribe({
      next: (res) => {
        this.roles = res;
        this.roleId = this.roles.filter(
          (role) => role.name === this.user.role
        )[0].id;
        this.editUserForm.patchValue({
          roleId: this.roleId,
        });
      },
      error: (err) => {
        console.error({ err });
      },
    });
  }

  editUser() {
    if(this.editUserForm.invalid){
      this.submitted = true
      Object.keys(this.editUserForm.controls).forEach(key => {
        this.editUserForm.get(key)!.markAsTouched();
      })
      return;
    }
    this.usersService.editUser(this.editUserForm.value).subscribe({
      next: res=>{
        this.dialogRef.close(true)
        this.nbToaster.show('User Edited successfully.', 'success', {
          status: 'success',
          hasIcon: false,
          icon: 'checkmark-outline'
        });
      },
      error: (err) => {
        console.error({ err });
        this.nbToaster.show(err, 'error', {
          status: 'danger',
          hasIcon: false,
          icon: 'close-outline',
        });
      },
    });
  }
  onClose(msg: string) {
    this.dialogRef.close(msg);
  }
  cancel() {
    this.dialogRef.close();
  }
}
